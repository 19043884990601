export enum EIconPercentSize {
  SMALL = 80,
  STANDARD = 90,
  MEDIUM = 100,
  LARGE = 110,
  XLARGE = 120,
}

export enum EIconPixelSize {
  DEFAULT_CONTROL = 28,
  DEFAULT_FUNCTION = 20,
}

export const EIconSizePixelToPercentControlMap = new Map<number, number>([
  [parseFloat((EIconPixelSize.DEFAULT_CONTROL! * (EIconPercentSize.SMALL / 100)).toFixed(0)), EIconPercentSize.SMALL],
  [
    parseFloat((EIconPixelSize.DEFAULT_CONTROL! * (EIconPercentSize.STANDARD / 100)).toFixed(0)),
    EIconPercentSize.STANDARD,
  ],
  [parseFloat((EIconPixelSize.DEFAULT_CONTROL! * (EIconPercentSize.MEDIUM / 100)).toFixed(0)), EIconPercentSize.MEDIUM],
  [parseFloat((EIconPixelSize.DEFAULT_CONTROL! * (EIconPercentSize.LARGE / 100)).toFixed(0)), EIconPercentSize.LARGE],
  [parseFloat((EIconPixelSize.DEFAULT_CONTROL! * (EIconPercentSize.XLARGE / 100)).toFixed(0)), EIconPercentSize.XLARGE],
]);

export const EIconSizePixelToPercentFunctionMap = new Map<number, number>([
  [parseFloat((EIconPixelSize.DEFAULT_FUNCTION! * (EIconPercentSize.SMALL / 100)).toFixed(0)), EIconPercentSize.SMALL],
  [
    parseFloat((EIconPixelSize.DEFAULT_FUNCTION! * (EIconPercentSize.STANDARD / 100)).toFixed(0)),
    EIconPercentSize.STANDARD,
  ],
  [
    parseFloat((EIconPixelSize.DEFAULT_FUNCTION! * (EIconPercentSize.MEDIUM / 100)).toFixed(0)),
    EIconPercentSize.MEDIUM,
  ],
  [parseFloat((EIconPixelSize.DEFAULT_FUNCTION! * (EIconPercentSize.LARGE / 100)).toFixed(0)), EIconPercentSize.LARGE],
  [
    parseFloat((EIconPixelSize.DEFAULT_FUNCTION! * (EIconPercentSize.XLARGE / 100)).toFixed(0)),
    EIconPercentSize.XLARGE,
  ],
]);

export const EIconSizePixelToPercent = new Map<boolean, Map<number, number>>([
  [false, EIconSizePixelToPercentControlMap],
  [true, EIconSizePixelToPercentFunctionMap],
]);

// export const EIconSizePixelToPercent = [
//   {
//     isFunction: false,
//     maps: EIconSizePixelToPercentControlMap
//   },
//   {
//     isFunction: true,
//     maps: EIconSizePixelToPercentFunctionMap
//   }
// ]

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NavLink.css";
import { Link, Typography, useTheme } from "@mui/material";

interface INavbarProps {
  translationKey: string;
  path: string;
}

const NavLink: FC<INavbarProps> = ({ translationKey, path }: INavbarProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  return pathname === path ? (
    <div className="nav__linkContainer">
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => navigate(path)}
      >
        <Typography
          variant="subtitle1"
          className="nav__link"
          fontWeight={700}
        >
          {t(translationKey)}
        </Typography>
      </Link>
      <div
        style={{ borderBottom: `4px solid ${theme.palette.primary.main}` }}
        className="nav__linkBorder"
      ></div>
    </div>
  ) : (
    <div className="nav__linkContainer">
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => navigate(path)}
      >
        <Typography
          variant="subtitle1"
          className="nav__link"
        >
          {t(translationKey)}
        </Typography>
      </Link>
    </div>
  );
};

export default NavLink;

import { Button, ButtonProps, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import { LoadingButton } from "@mui/lab";
import { ReactSVG } from "react-svg";
import AddIcon from "../Icons/AddIcon.svg";
import FitText from "../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  handleAddCoverSet: () => Promise<void>;
}

const AbbAddCoverSet: React.FC<IButtonProps> = ({ loading, handleAddCoverSet }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  return (
    <LoadingButton
      sx={{ width: 178, height: 40, borderColor: "#DBDBDB" }}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      onClick={handleAddCoverSet}
      variant="outlined"
      endIcon={
        <ReactSVG
          src={AddIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}`
            );
          }}
        />
      }
      title={t("DesignSummary_AddCoverSet")}
    >
      <FitText sx={{ fontSize: 14, fontWeight: 500, color: ftsTheme.icon.color }}>{t("DesignSummary_AddCoverSet")}</FitText>
    </LoadingButton>
  );
};

const BjeAddCoverSet: React.FC<IButtonProps> = ({ loading, handleAddCoverSet }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  return (
    <LoadingButton
      sx={{ width: 166, height: 44, borderColor: "#DBDBDB" }}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      onClick={handleAddCoverSet}
      variant="outlined"
      endIcon={
        <ReactSVG
          src={AddIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}`
            );
          }}
        />
      }
      title={t("DesignSummary_AddCoverSet")}
    >
      <FitText>{t("DesignSummary_AddCoverSet")}</FitText>
    </LoadingButton>
  );
};

const AddCoverSetButton: React.FC<IButtonProps> = ({ loading, handleAddCoverSet }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbAddCoverSet
          loading={loading}
          handleAddCoverSet={handleAddCoverSet}
        />
      )) ||
        (theme.name == BJE && (
          <BjeAddCoverSet
            loading={loading}
            handleAddCoverSet={handleAddCoverSet}
          />
        ))}
    </>
  );
};

export default AddCoverSetButton;

import app, { IAppModel } from "./app";
import auth, { IAuthModel } from "./auth";
import countries, { ICountryModel } from "./country";
import currencies, { ICurrencyModel } from "./currency";
import designs, { IDesignModel } from "./design";
import languages, { ILanguageModel } from "./language";
import { persist } from 'easy-peasy'

export interface StoreModel {
  app: IAppModel;
  auth: IAuthModel;
  designs: IDesignModel;
  countries: ICountryModel;
  languages: ILanguageModel;
  currencies: ICurrencyModel;
}

const model: StoreModel = {
  app: app,
  auth: persist(auth, { storage: 'localStorage' }),
  designs: designs,
  countries: persist(countries, { storage: 'localStorage' }),
  languages: persist(languages, { storage: 'localStorage' }),
  currencies: persist(currencies, { storage: 'localStorage' }),
};

export default model;

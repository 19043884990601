import { Box, Divider, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { getSignOutHref } from "../../auth/config";
import { useAuth } from "../../auth/utils";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import logging from "../../config/logging";
import store from "../../store";
import "./Profile.css";
import ProfileImg from "../../images/Profile.png";
import SettingsPrivacyTab from "./SettingsPrivacyTab";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";
import useAppTitle from "../../hooks/useAppTitle";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={"70px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Profile: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { actions: authActions } = useAuth();
  const appTitle = useAppTitle()

  const handleSignOut = () => {
    store.persist
      .clear()
      .then(() => {
        authActions.removeAllData();
        window.location.replace(getSignOutHref());
      })
      .catch((err) => logging.error(err));
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fafafa"
      bodyBackGroundColor="#fafafa"
      customPageHeaderOffSection={
        <div
          className="ProfileImg"
          style={{
            width: "100%",
            backgroundImage: `url(${ProfileImg})`,
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      }
    >
      <BackButton />
      <Grid
        container
        pt={"5px"}
        pb={"32px"}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
        >
          <Typography
            variant="h4"
            fontSize={"32px"}
            fontWeight={"700"}
          >
            {t("profile_page.label.title")}
          </Typography>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            marginTop: "31px",
            marginBottom: "12px",
          }}
        />

        <Grid
          item
          md={12}
          xl={12}
        >
          <Tabs
            value={0}
            aria-label="profile tabs"
            sx={{
              borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
              "& .MuiTabs-flexContainer": {
                justifyContent: "space-between",
              },
            }}
            TabIndicatorProps={{
              style: {
                height: "4px",
              },
            }}
          >
            <Tab
              sx={{
                color: "#000000",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "140%",
                "&.Mui-selected": { fontWeight: 700, color: theme.palette.primary.main },
              }}
              label={t("profile_page.tabs.label.tab_settings_privacy")}
            />
            <Tab
              onClick={handleSignOut}
              sx={{
                color: "#000000",
                textTransform: "unset",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "140%",
                textDecoration: "underline",
                alignSelf: "end",
                "&.Mui-selected": { fontWeight: 700, color: theme.palette.primary.main },
              }}
              label={t("profile_page.tabs.label.tab_sign_out")}
            />
          </Tabs>

          <TabPanel
            value={0}
            index={0}
          >
            <SettingsPrivacyTab />
          </TabPanel>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
};

export default Profile;

export enum EDesignStatus {
  Draft = 0,
  Completed = 1
}

export const EDesignStatusDescription = new Map<EDesignStatus, string>(
  [
    [EDesignStatus.Draft, "DesignSummary_InDraft"],
    [EDesignStatus.Completed, "DesignSummary_Completed"]
  ]
)
import { Box, Button, ButtonGroup, ButtonProps, IconButton, Typography, useTheme } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";

interface IButtonProps extends ButtonProps {
  disabledDecrement: boolean;
  disabledIncrement: boolean;
  iconDecrementColor: string;
  iconIncrementColor: string;
  buttonDisabledColor: string;
  currentQuantity: string;
  handleDecrement: () => void;
  handleIncrement: () => void;
}

const AbbQuantityCounter: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <Box sx={{
      justifyContent: "center",
      width: "100%",
      display: "flex"
    }}>
    <ButtonGroup
      size="large"
      aria-label="large button group"
      sx={{
        height: 33,
        width: 82,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <IconButton
        color="primary"
        onClick={handleDecrement}
        disabled={disabledDecrement}
        sx={{ 
          width: 16, height: 16 
        }}
      >
        <RemoveOutlinedIcon
          style={{
            fill: iconDecrementColor,
          }}
        />
      </IconButton>
      <Button
        disabled
        className={`projectSummaryTable_cell_quantity_bje${buttonDisabledColor}`}
        variant="text"
        sx={{ 
          border: "1px solid #c4c4c4",
          borderRadius: 4,
          width: "38px !important", 
          height: "33px !important",
          minWidth: 38
        }}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#000000" }}>{currentQuantity}</Typography>
      </Button>
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
        sx={{
          width: 16, height: 16
        }}
      >
        <AddOutlinedIcon style={{ fill: iconIncrementColor }} />
      </IconButton>
    </ButtonGroup>
    </Box>
  );
};

const BjeQuantityCounter: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <ButtonGroup
    sx={{
      height: 33,
      width: 82,
      alignItems: 'center'
    }}>
      <IconButton
        color="primary"
        onClick={handleDecrement}
        disabled={disabledDecrement}
        sx={{ 
          width: 16, height: 16 
        }}
      >
        <RemoveOutlinedIcon
          style={{
            fill: iconDecrementColor,
          }}
        />
      </IconButton>
      <Button
        sx={{
          border: "1px solid #c4c4c4",
          borderRadius: 4,
          width: 16, height: 16 

        }}
        disabled
        className={`projectSummaryTable_cell_quantity_bje${buttonDisabledColor}`}
        variant="text"
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: "#000",
          }}
        >
          {currentQuantity}
        </Typography>
      </Button>
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
        sx={{ 
          width: 16, height: 16 
        }}
      >
        <AddOutlinedIcon style={{ fill: iconIncrementColor }} />
      </IconButton>
    </ButtonGroup>
  );
};

const QuantityCounterButtonGroup: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbQuantityCounter
          disabledDecrement={disabledDecrement}
          disabledIncrement={disabledIncrement}
          iconDecrementColor={iconDecrementColor}
          iconIncrementColor={iconIncrementColor}
          buttonDisabledColor={buttonDisabledColor}
          currentQuantity={currentQuantity}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      )) ||
        (theme.name == BJE && (
          <BjeQuantityCounter
            disabledDecrement={disabledDecrement}
            disabledIncrement={disabledIncrement}
            iconDecrementColor={iconDecrementColor}
            iconIncrementColor={iconIncrementColor}
            buttonDisabledColor={buttonDisabledColor}
            currentQuantity={currentQuantity}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
          />
        ))}
    </>
  );
};

export default QuantityCounterButtonGroup;

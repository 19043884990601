import { Action, action } from "easy-peasy";
import { ICurrency } from "../interfaces/ICurrency";

export interface ICurrencyModel {
  currencies: ICurrency[];
  setCurrencies: Action<ICurrencyModel, ICurrency[]>;
}

const currencies: ICurrencyModel = {
  currencies: [],
  setCurrencies: action((state, payload) => {
    state.currencies = payload;
  }),
};

export default currencies;

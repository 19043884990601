import { Button, ButtonGroup, ButtonProps, IconButton, Typography, useTheme } from "@mui/material";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

interface IButtonProps extends ButtonProps {
  numberOfSwitches: number;
  disabledDecrement: boolean;
  disabledIncrement: boolean;
  fillIconDecrement: string;
  fillIconIncrement: string;
  handleDecrement: () => Promise<void>;
  handleIncrement: () => Promise<void>;
}

const AbbHowManySwitchesCounter: React.FC<IButtonProps> = ({
  numberOfSwitches,
  disabledDecrement,
  disabledIncrement,
  fillIconDecrement,
  fillIconIncrement,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <ButtonGroup
      size="large"
      aria-label="large button group"
      sx={{
        borderRadius: 1,
        border: "1px solid #DBDBDB",
        width: 128,
        height: 40,
      }}
    >
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleDecrement}
        disabled={disabledDecrement}
      >
        <RemoveOutlinedIcon style={{ fill: fillIconDecrement }} />
      </IconButton>
      <Button
        className="frameConfiguration_cell_quantity_abb"
        disabled
        variant="text"
        sx={{
          border: 0,
        }}
      >
        <Typography sx={{ color: "#000", fontSize: 14, fontWeight: 500 }}>{numberOfSwitches}</Typography>
      </Button>
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
      >
        <AddOutlinedIcon style={{ fill: fillIconIncrement }} />
      </IconButton>
    </ButtonGroup>
  );
};

const BjeHowManySwitchesCounter: React.FC<IButtonProps> = ({
  numberOfSwitches,
  disabledDecrement,
  disabledIncrement,
  fillIconDecrement,
  fillIconIncrement,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <ButtonGroup
      size="large"
      aria-label="large button group"
    >
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleDecrement}
        disabled={disabledDecrement}
      >
        <RemoveOutlinedIcon style={{ fill: fillIconDecrement }} />
      </IconButton>
      <Button
        className="frameConfiguration_cell_quantity_bje"
        disabled
        variant="text"
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: 0,
        }}
      >
        {numberOfSwitches}
      </Button>
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
      >
        <AddOutlinedIcon style={{ fill: fillIconIncrement }} />
      </IconButton>
    </ButtonGroup>
  );
};

const HowManySwitchesCounter: React.FC<IButtonProps> = ({
  numberOfSwitches,
  disabledDecrement,
  disabledIncrement,
  fillIconDecrement,
  fillIconIncrement,
  handleDecrement,
  handleIncrement,
}) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbHowManySwitchesCounter
          numberOfSwitches={numberOfSwitches}
          disabledDecrement={disabledDecrement}
          disabledIncrement={disabledIncrement}
          fillIconDecrement={fillIconDecrement}
          fillIconIncrement={fillIconIncrement}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      )) ||
        (theme.name == BJE && (
          <BjeHowManySwitchesCounter
            numberOfSwitches={numberOfSwitches}
            disabledDecrement={disabledDecrement}
            disabledIncrement={disabledIncrement}
            fillIconDecrement={fillIconDecrement}
            fillIconIncrement={fillIconIncrement}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
          />
        ))}
    </>
  );
};

export default HowManySwitchesCounter;

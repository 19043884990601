import { NavbarLink } from "./NavbarLink";
import { NavbarLinks } from "./NavbarLinks";
import { NavbarLogo } from "./NavbarLogo";
import { NavbarRoot } from "./NavbarRoot";
import { NavbarTitle } from "./NavbarTitle";
import { NavbarUser } from "./NavbarUser";

export const NavbarNew = {
  Root: NavbarRoot,
  Links: NavbarLinks,
  Link: NavbarLink,
  Logo: NavbarLogo,
  Title: NavbarTitle,
  User: NavbarUser,
};

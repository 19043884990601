import { FC } from "react";
import { useStoreActions } from "../../../hooks";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import { IProductDesignResponse } from "../../../interfaces/Project/Response/ProductDesignResponse";
import Cover from "../Components/Cover/Cover";
import FrameThumb from "../Components/Frame/FrameThumb";
import LayoutConfiguration from "../InsertConfiguration/Tiles/LayoutConfiguration";
import { designPreviewDialogRef } from "./DesignPreviewDialog";
import "./DesignPreviewThumb.css";

interface IDesignPreviewThumbInputProps {
  productDesignId: string;
  productDesign: IProductDesignResponse;
}

const DesignPreviewThumb: FC<IDesignPreviewThumbInputProps> = (props) => {
  const { setProductDesignPreview, setUserDesignDataFromProductDesignResponse } = useStoreActions(
    (actions) => actions.designs
  );

  const handleOpenPreview = (productDesign: IProductDesignResponse) => {
    setProductDesignPreview(productDesign);
    setUserDesignDataFromProductDesignResponse(productDesign);

    designPreviewDialogRef.current?.open();
  };

  return (
    <>
      {(!props.productDesign?.design && <div className="thumb"></div>) || (
        <div
          className="thumb"
          onClick={() => handleOpenPreview(props.productDesign!)}
        >
          <div
            className={
              props.productDesign?.isHorizontal!
                ? `designPreview__mainPreview--horizontal-thumb`
                : `designPreview__mainPreview--vertical-thumb`
            }
          >
            <FrameThumb
              numberOfSwitches={props.productDesign?.insertDetails.length!}
              isHorizontal={props.productDesign?.isHorizontal!}
              design={props.productDesign?.design!}
              frameColor={props.productDesign?.frameColor!}
              enableSelection={false}
              enableTransition={false}
            >
              {props.productDesign?.insertDetails.map((detail: IInsertDetail, coverIndex: number) => (
                <Cover
                  key={`cover-${coverIndex}`}
                  cover={coverIndex}
                  insertDetail={detail}
                  designCode={props.productDesign?.design?.code!}
                  isHorizontal={props.productDesign?.isHorizontal!}
                >
                  <LayoutConfiguration
                    designCode={props.productDesign?.design?.code!}
                    isHorizontal={props.productDesign?.isHorizontal!}
                    key={`layout-configuration-preview-${coverIndex}`}
                    insertDetail={detail}
                    setInsertDetail={(detail: IInsertDetail) => {}} //change property optional
                    cover={coverIndex}
                    insertType={detail.insertType?.name}
                    isPreview={true}
                    canManageRef={false}
                  />
                </Cover>
              ))}
            </FrameThumb>
          </div>
        </div>
      )}
    </>
  );
};

export default DesignPreviewThumb;

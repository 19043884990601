import { t } from "i18next";
import React, { Dispatch, RefObject, SetStateAction } from "react";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import { ICanvasForwardProps } from "../../../pages/Configurator/InsertConfiguration/Tiles/Canvas";
import "./IconDialog.css";
import "./TextAreaControlled.css";
import { useTheme } from "@mui/material";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";

interface IProps {
  fontSize: number;
  textAreaValue: string;
  displayHighlightedWarning: { display: boolean; start: number; end: number };
  canvasComponentRef: RefObject<ICanvasForwardProps>;
  setTextAreaValue: Dispatch<SetStateAction<string>>;
  insertDetail: IInsertDetail;
}

const TextAreaControlled: React.FC<IProps> = (props) => {
  const ftsTheme = useTheme() as FTSTheme;
  return (
    <>
      <textarea
        style={{ fontFamily: `${ftsTheme.name == ABB ? "ABBVoice" : "BJEAverta"}` }}
        className="textarea"
        value={props.textAreaValue}
        placeholder={t("InsertConfiguration_IconDialog_TextareaPlaceholder")}
        onFocus={(e) => (e.target.placeholder = "")}
        onBlur={(e) => (e.target.placeholder = t("InsertConfiguration_IconDialog_TextareaPlaceholder"))}
        onKeyPress={(event) => {
          if (!["Backspace", "Delete"].includes(event.key)) {
            props.canvasComponentRef.current?.handleOnKeyPress(event);
          }
          let isSpace = event.currentTarget.value.slice(-1) == " " && event.nativeEvent.code === "Space";
          if (isSpace) {
            event.preventDefault();
            return;
          }
        }}
        onKeyUp={(event) =>
          props.canvasComponentRef.current?.handleOnKeyUp(
            event.currentTarget.value,
            event.currentTarget.selectionStart,
            event.currentTarget.selectionEnd
          )
        }
        onChange={(event) => {
          props.setTextAreaValue(event.target.value);
        }}
      ></textarea>

      <div
        className="div-textarea-highlight"
        style={{ fontFamily: `${ftsTheme.name == ABB ? "ABBVoice" : "BJEAverta"}` }}
      >
        {props.textAreaValue.substring(0, props.displayHighlightedWarning.start)}
        {props.displayHighlightedWarning.display && (
          <mark style={{ lineHeight: "16.5px", backgroundColor: "#F8ECAC" }}>
            {props.textAreaValue.substring(props.displayHighlightedWarning.start, props.textAreaValue.length)}
          </mark>
        )}
      </div>
    </>
  );
};

export default TextAreaControlled;

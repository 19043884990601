import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { ICountry } from "../interfaces/ICountry";
import { ECompany } from "../enum/ECompany";
const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axiosInstance.get(url).then(responseBody),
};

const CountryService = {
  get: (): Promise<ICountry[]> => requests.get(`countries/byCompany/${(process.env.REACT_APP_THEME == 'bje' ? ECompany.BJE : ECompany.ABB)}`),
  getCountryNameByCountryCode: (countryCode: string): Promise<ICountry> =>
    requests.get(`countries/${countryCode}`),
};

export default CountryService;

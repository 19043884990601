import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IDesign } from "../interfaces/IDesign";
import { ECompany } from "../enum/ECompany";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
};

const DesignService = {
  get: (countryId: number): Promise<AxiosResponse<IDesign[]>> =>
    requests.get(`design/company/${(process.env.REACT_APP_THEME == 'bje' ? ECompany.BJE : ECompany.ABB)}/country/${countryId}`),
  getById: (id: string): Promise<AxiosResponse<IDesign>> => requests.get(`design/${id}`),
};

export default DesignService;

import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";
import { selectExistingProjectDialogRef } from "../WelcomeModal/OpenProjectDialog/SelectExistingProjectDialog";
import FitText from "../../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {}

const AbbOpenProject: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  const selectExistingProject = () => {
    selectExistingProjectDialogRef.current?.open();
  };

  return (
    <Button
      sx={{ width: 188, height: 44, border: "1px solid var(--primary-color-grey-20, #DBDBDB)" }}
      variant="outlined"
      onClick={selectExistingProject}
      endIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3.33301 6.66671V8.00004H2.66634V13.3334H7.99967V12.6667H9.33301V14C9.33301 14.1769 9.26277 14.3464 9.13775 14.4714C9.01272 14.5965 8.84315 14.6667 8.66634 14.6667H1.99967C1.82286 14.6667 1.65329 14.5965 1.52827 14.4714C1.40325 14.3464 1.33301 14.1769 1.33301 14V7.33337C1.33301 7.15656 1.40325 6.98699 1.52827 6.86197C1.65329 6.73695 1.82286 6.66671 1.99967 6.66671H3.33301ZM13.9997 1.33337C14.1765 1.33337 14.3461 1.40361 14.4711 1.52864C14.5961 1.65366 14.6663 1.82323 14.6663 2.00004V10.6667C14.6663 10.8435 14.5961 11.0131 14.4711 11.1381C14.3461 11.2631 14.1765 11.3334 13.9997 11.3334H7.33301L8.66634 10H13.333V2.66671H5.99967V7.33337L4.66634 8.66671V2.00004C4.66634 1.82323 4.73658 1.65366 4.8616 1.52864C4.98663 1.40361 5.1562 1.33337 5.33301 1.33337H13.9997ZM11.333 4.66671V8.66671H9.99967V6.94004L5.80634 11.14L4.85967 10.1934L9.05967 6.00004H7.33301V4.66671H11.333Z"
            fill="black"
          />
        </svg>
      }
    >
      <FitText sx={{ fontWeight: 500, color: "#000000" }}>{t("openProject")}</FitText>
    </Button>
  );
};

const BjeOpenProject: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectExistingProject = () => {
    selectExistingProjectDialogRef.current?.open();
  };

  return (
    <Button
      className="welcomePage__btn welcomePage__openProjectBtn"
      variant="outlined"
      onClick={selectExistingProject}
      endIcon={
        <svg
          width="20"
          height="20"
        >
          <path
            d="M3.5 8V10H2.5V18H10.5V17H12.5V19C12.5 19.2652 12.3946 19.5196 12.2071 19.7071C12.0196 19.8946 11.7652 20 11.5 20H1.5C1.23478 20 0.98043 19.8946 0.792893 19.7071C0.605357 19.5196 0.5 19.2652 0.5 19V9C0.5 8.73478 0.605357 8.48043 0.792893 8.29289C0.98043 8.10536 1.23478 8 1.5 8H3.5ZM19.5 0C19.7652 0 20.0196 0.105357 20.2071 0.292893C20.3946 0.48043 20.5 0.734784 20.5 1V14C20.5 14.2652 20.3946 14.5196 20.2071 14.7071C20.0196 14.8946 19.7652 15 19.5 15H9.5L11.5 13H18.5V2H7.5V9L5.5 11V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H19.5ZM15.5 5V11H13.5V8.41L7.21 14.71L5.79 13.29L12.09 7H9.5V5H15.5Z"
            fill={theme.palette.primary.main}
          />
        </svg>
      }
    >
      <FitText>{t("openProject")}</FitText>
    </Button>
  );
};

const OpenProjectButton: React.FC = () => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbOpenProject />) || (theme.name == BJE && <BjeOpenProject />)}</>;
};

export default OpenProjectButton;

import { Backdrop, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";

export enum EBackdropStatus {
  Loading = 1,
  Success = 2,
  Error = 3,
  None = 4,
  Warning = 5
}

export const EBackdropStatusDescription = new Map<EBackdropStatus, string>([
  [EBackdropStatus.Loading, "Loading"],
  [EBackdropStatus.Success, "Success"],
  [EBackdropStatus.Error, "Error"],
  [EBackdropStatus.None, "None"],
  [EBackdropStatus.Warning, "Warning"],
]);

export const EBackdropVariantByStatus = new Map<string, EBackdropStatus>([
  ['error', EBackdropStatus.Error],
  ['warning', EBackdropStatus.Warning]
]);

interface IBackdropCallbackButton {
  text: string;
  function?: Function;
  buttonsize?: string;
}
interface IOpenBackdropProps {
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  status?: EBackdropStatus;
  callbackOkButton?: IBackdropCallbackButton;
  callbackCancelButton?: IBackdropCallbackButton;
}
interface IBackdropContext {
  openBackdrop(props?: IOpenBackdropProps): void;
  closeBackdrop(): void;
  isOpen: boolean;
}
interface IBackdropProps {
  children: React.ReactNode;
}

const BackdropContext = createContext<IBackdropContext>({} as IBackdropContext);

const BackdropProvider: React.FC<IBackdropProps> = (props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [message, setMessage] = useState<string | JSX.Element>('');
  const [status, setStatus] = useState<EBackdropStatus>(EBackdropStatus.Loading);
  const [callbackOk, setCallbackOk] = useState<IBackdropCallbackButton | null>(null);
  const [callbackCancel, setCallbackCancel] = useState<IBackdropCallbackButton | null>(null);
  const [open, setOpen] = useState(false);

  const openBackdrop = useCallback((props: IOpenBackdropProps) => {
    setTitle(props.title ? props.title : '');
    setMessage(props.message ? props.message : '');
    setStatus(props.status ? props.status : EBackdropStatus.Loading);
    setCallbackOk(props.callbackOkButton ? props.callbackOkButton : null);
    setCallbackCancel(props.callbackCancelButton ? props.callbackCancelButton : null);

    setOpen(true);
  }, []);

  const closeBackdrop = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCallbackOk = useCallback(() => {
    closeBackdrop();
    if (callbackOk?.function)
      callbackOk.function();
  }, [callbackOk])

  const handleCallbackCancel = useCallback(() => {
    closeBackdrop();
    if (callbackCancel?.function)
      callbackCancel.function();
  }, [callbackCancel])

  return (
    <BackdropContext.Provider
      value={{
        openBackdrop,
        closeBackdrop,
        isOpen: open
      }}>
      {props.children}
      <Backdrop
        style={{ zIndex: 9999 }}
        open={open}
      >
        <Paper sx={{
          paddingTop: '70px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '438px',
          minHeight: '247px',
          borderRadius: '4px'
        }}>
          {
            status === EBackdropStatus.Loading &&
            <CircularProgress size={60} color="primary" />
          }

          {/* {
            status === EBackdropStatus.Success &&
            <AbbIcon icon="success" color="#0CA919" size={70} />
          }

          {
            status === EBackdropStatus.Error &&
            <AbbIcon icon="error" color="#FF000F" size={70} />
          }
          {
            status === EBackdropStatus.Warning &&
            <AbbIcon icon="configuration-warning" size={70} />
          } */}

          {
            title &&
            <Typography
              mt={2}
              variant="h6"
              fontWeight="700"
              fontSize="16px"
              lineHeight="20px"
              color="#1F1F1F"
              style={{
                maxWidth: '450px',
                maxHeight: '150px',
                overflow: 'auto',

              }}>
              {title}
            </Typography>
          }
          {
            typeof (message) == 'string' &&
            <Typography
              mt={1}
              variant="h6"
              fontWeight="300"
              fontSize="16px"
              style={{
                maxWidth: '450px',
                maxHeight: '150px',
                overflow: 'auto',
                whiteSpace: 'pre-line'
              }}>
              {message}
            </Typography>
          }

          {
            typeof (message) != 'string' &&
            { ...message }
          }

          <div style={{ marginTop: "42px" }}>
            {
              (callbackCancel != null) &&
              <Button
                sx={{
                  mt: 1,
                  width: callbackCancel.buttonsize ?? "94px",
                  height: "32px",
                }}
                variant="outlined"
                color="inherit"
                onClick={handleCallbackCancel}
              >
                {
                  callbackCancel?.text ?? 'Cancel'
                }
              </Button>
            }

            {
              callbackOk &&
              <Button
                sx={{
                  mt: 1,
                  marginLeft: "16px",
                  width: callbackOk.buttonsize ?? "94px",
                  height: "32px",
                  textTransform: "capitalize"

                }}
                variant="contained"
                color="primary"
                onClick={handleCallbackOk}
              >
                {callbackOk?.text}
              </Button>
            }
          </div>
        </Paper>
      </Backdrop>
    </BackdropContext.Provider >
  )
}

const useBackdrop = () => {
  return useContext(BackdropContext);
}

export { BackdropProvider, useBackdrop };
let timeoutId: ReturnType<typeof setTimeout>;

export function executeWithDebounce<T>(fn: Function, values: T, time?: number, callback?: Function) {
  const execute = debounce(fn, time ?? 1000, callback);
  execute(values);
}

export const debounce = (fn: Function, ms: number, callback?: Function) => {
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(this, args)

      if(callback)
        callback();
    }, ms);
  };
};


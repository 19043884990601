import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { ForwardRefRenderFunction, createRef, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { FTSTheme } from "../../../../App";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { IDialogHandle } from "../../../../interfaces/IDialogHandle";
import { designPreviewDialogRef } from "../../DesignPreview/DesignPreviewDialog";
import CloseIcon from "../../Icons/close.svg";

export const emptyCoverDialogRef = createRef<IDialogHandle>();

interface IEmptyCoverDialog {

}

const EmptyCoverDialog: ForwardRefRenderFunction<IDialogHandle, IEmptyCoverDialog> = (props, ref) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const [open, setOpen] = useState(false);
  const EMPTY_COVER_IMAGE = `./assets/images/Empty-covers-${ftsTheme.name}.png`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "438px",
            },
          },
        }}
        fullWidth={true}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 35,
            height: 35,
          }}
        >
          <ReactSVG
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top: 5px`
              );
            }}
          />
        </IconButton>
        <DialogContent>
          <div style={{
            width: '390px',
            height: '334px',
            marginTop: '30px',
          }}>
            <img src={EMPTY_COVER_IMAGE} alt="empty-covers" style={{
              width: '100%'
            }} />
          </div>

          <Typography fontSize={20} fontWeight={500}>{t("emptycoversdialog.title")}</Typography>
          <Typography fontSize={14} fontWeight={400}>{t("emptycoversdialog.message")}</Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              sx={{ width: 189, height: 44, marginTop: '24px' }}
              onClick={handleClose}
              variant="contained"
              style={{ textTransform: "unset" }}
            >
              {t("emptycoversdialog.button")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(EmptyCoverDialog);
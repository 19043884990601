import React, { FC } from "react";
import Navbar from "../Navbar/Navbar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Footer from "../Footer/Footer";
import "./ExternalLayout.css";

interface IExternalLayoutProps {
  children: React.ReactNode;
  showNavbarLinks: boolean;
  showNavbarCenteredText: boolean;
  navbarCenteredText?: string;
  navbarBackGroundColor?: string;
  bodyBackGroundColor?: string;
  navbarMainTitle?: string;
  navbarLogoLink: string;
  showPageHeader: boolean;
  pageHeaderTextTranslationKey?: string;
  customPageHeader?: string;
  customPageHeaderOffSection?: JSX.Element;
}

const ExternalLayout: FC<IExternalLayoutProps> = ({
  children,
  navbarBackGroundColor,
  bodyBackGroundColor,
  navbarMainTitle,
  navbarLogoLink,
  pageHeaderTextTranslationKey,
  showNavbarLinks,
  navbarCenteredText,
  showNavbarCenteredText,
  showPageHeader,
  customPageHeader,
  customPageHeaderOffSection,
}) => {
  const { t } = useTranslation();

  const welcomeMessage = t(pageHeaderTextTranslationKey!).split(" ");

  return (
    <>
      <Navbar
        backgroundColor={navbarBackGroundColor}
        showNavbarCenteredText={showNavbarCenteredText}
        navbarCenteredText={navbarCenteredText}
        showNavbarLinks={showNavbarLinks}
        mainTitle={navbarMainTitle}
        logoLink={navbarLogoLink}
        showRegionBtn={true}
      />
      <main
        style={{
          backgroundColor: bodyBackGroundColor ? bodyBackGroundColor : "#fff",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {customPageHeaderOffSection !== undefined && customPageHeaderOffSection}
        <section className="content-section">
          {showPageHeader && (
            <div className="pageHeader">
              <Typography
                variant="h6"
                fontWeight="400"
                fontSize="18px"
                mt="24px"
                className="pageHeader__span"
              >
                {welcomeMessage[0] + " " + welcomeMessage[1]}
              </Typography>
              <Typography
                variant="h1"
                className="pageHeader__title"
              >
                {welcomeMessage[2] + " " + welcomeMessage[3] + " " + welcomeMessage[4]}
              </Typography>
            </div>
          )}
          {customPageHeader !== undefined && (
            <div className="pageHeader">
              <Typography
                variant="h4"
                gutterBottom
                component="div"
              >
                {t(customPageHeader)}
              </Typography>
            </div>
          )}
          {children}
        </section>
      </main>
      <Footer showCopyrightInfo={true} />
    </>
  );
};

export default ExternalLayout;

import axios from "axios";

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASEURL,
});

// axiosAuthInstance.interceptors.request.use(function (config) {
//   let token = localStorage.getItem("token");
//   config.headers["Authorization"] = "Bearer " + token;
//   return config;
// });

export default axiosAuthInstance;

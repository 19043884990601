import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../../App";
import { ABB, BJE } from "../../../../../constants/Theme";
import { Link } from "react-router-dom";
import FitText from "../../../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  loading: boolean;
}

const AbbBackButton: React.FC<IButtonProps> = ({ loading }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <Link to="/dashboard">
      <Button
        sx={{
          width: 96,
          height: 40,
          display: "flex",
        }}
        variant="text"
        disabled={loading}
      >
        <FitText sx={{ fontSize: "14px", fontWeight: 500 }}>
          {t(`${theme.name}.configurator_welcome_dialog.button.back.title`)}
        </FitText>
      </Button>
    </Link>
  );
};

const BjeBackButton: React.FC<IButtonProps> = ({ loading }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <Link to="/dashboard">
      <Button
        sx={{ width: 193, height: 41 }}
        variant="text"
        disabled={loading}
        style={{ textTransform: "unset" }}
      >
        <FitText>{t(`${theme.name}.configurator_welcome_dialog.button.back.title`)}</FitText>
      </Button>
    </Link>
  );
};

const BackButton: React.FC<IButtonProps> = ({ loading }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbBackButton loading={loading} />) ||
        (theme.name == BJE && <BjeBackButton loading={loading} />)}
    </>
  );
};

export default BackButton;

interface IFallBackProps {
  error: any;
}

const ErrorFallback: React.FC<IFallBackProps> = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}

export default ErrorFallback;
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import searchIllustration from "./search_illustration.png";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import "./BrowserNotSupported.css";
import { Tooltip } from "react-tooltip";

const BrowserNotSupported: FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
    >
      <div className="notSupported">
        <img
          className="notSupported__image"
          src={searchIllustration}
          alt="Browser not supported"
        />
        <h1 className="notSupported__title">{t("browserNotSupported")}</h1>
        <p className="notSupported__description">{t("browserNotSupportedDescription")}</p>
        <div className="notSupported__browserContainer">
          <div className="browserContainer__browserItem">
            <img
              className="browserLogo"
              src="/assets/images/Chrome.svg"
              alt="chrome"
              data-tip
              data-for="chrome"
            />
          </div>
          <Tooltip
            id="chrome"
            place="top"
          >
            Google Chrome
          </Tooltip>
          <div className="browserContainer__browserItem">
            <img
              className="browserLogo"
              src="/assets/images/Firefox.svg"
              alt="firefox"
              data-tip
              data-for="firefox"
            />
          </div>
          <Tooltip
            id="firefox"
            place="top"
          >
            Mozilla Firefox
          </Tooltip>
          <div className="browserContainer__browserItem">
            <img
              className="browserLogo"
              src="/assets/images/Microsoft_Edge.svg"
              alt="edge"
              data-tip
              data-for="edge"
            />
          </div>
          <Tooltip
            id="edge"
            place="top"
          >
            Microsoft Edge
          </Tooltip>
        </div>
        <p className="notSupported__description notSupported__description--extraMargin">
          {t("browserNotSupportedDescription2")}
          <Link to="/not-supported">{t("browserNotSupportedDescription3")}</Link>
        </p>
        <Link
          to="/"
          className="notSupported__loadBtn"
        >
          <RefreshOutlinedIcon fontSize="medium" />
          <span>{t("browserNotSupportedStillLoad")}</span>
        </Link>
      </div>
    </ExternalLayout>
  );
};

export default BrowserNotSupported;

import { FC } from "react";
import ContactUSDialog, { contactUsDialogRef } from "../Dialog/ContactUs/ContactUSDialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid, Divider, Typography, Link, useTheme } from "@mui/material";
import "./Footer.css";
import { FTSTheme } from "../../App";
import { BJE } from "../../constants/Theme";
import { useStoreState } from "../../hooks";
interface IFooterProps {
  showCopyrightInfo: boolean;
}

const GridDivider = () => {
  return (<Grid item>
    <Divider
      orientation="vertical"
      sx={{ height: "16px" }}
    />
  </Grid>)
}

const Footer: FC<IFooterProps> = ({ showCopyrightInfo }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;
  const { currentUser } = useStoreState((state) => state.auth);

  const navigate = useNavigate();

  const handleOpenContactUsDialog = () => {
    contactUsDialogRef.current?.open();
  };

  const handleOpenTermsAndConditions = () => {
    navigate("/terms-and-conditions");
  };

  const handleOpenPrivacyNotice = () => {
    navigate("/privacy-notice");
  };

  const handleOpenLicenseInformation = () => {
    window.open(`${process.env.REACT_APP_BLOB_STORAGE_URL}/files/LicenseInformation/license-information.pdf`, '_blank');
  }
  const handleOpenHowToInformation = () => {
    if (process.env.REACT_APP_THEME === "bje") {
      window.open(`${process.env.REACT_APP_BLOB_STORAGE_URL}/files/howTo/${process.env.REACT_APP_FILE_BJE_HOW_TO_FILE}.pdf`, '_blank');
    }
    else if (process.env.REACT_APP_THEME === "abb" && currentUser?.countryId === 21 || currentUser?.countryId === 30) {
      window.open(`${process.env.REACT_APP_BLOB_STORAGE_URL}/files/howTo/${process.env.REACT_APP_FILE_ABB_HOW_TO_FILE_DE}.pdf`, '_blank');
    } else if (process.env.REACT_APP_THEME === "abb") {
      window.open(`${process.env.REACT_APP_BLOB_STORAGE_URL}/files/howTo/${process.env.REACT_APP_FILE_ABB_HOW_TO_FILE_EN}.pdf`, '_blank');
    }
  }

  return (
    <>
      <footer className="footer">
        {showCopyrightInfo && (
          <>
            <Grid
              container
              md={12}
            >
              <Grid
                container
                md={8}
                sx={{ rowGap: "20px" }}
                alignContent="center"
              >
                <Grid
                  item
                  mr={3}
                >
                  <Typography variant="caption" fontSize="16px" fontWeight={700}>
                    <small className="footer-copy">&copy; {t(`${theme.name}.footer.label.copy`)} {theme.name == BJE ? new Date().getFullYear() : ''}</small>
                  </Typography>
                </Grid>
                <GridDivider />
                <Grid
                  item
                  mr={3}
                  alignContent="center"
                >
                  <Link
                    underline="none"
                    onClick={() => {
                      handleOpenPrivacyNotice();
                    }}
                  >
                    <Typography className="footer-titles">{t("footer_privacy_policy")}</Typography>
                  </Link>
                </Grid>
                <GridDivider />
                <Grid
                  item
                  mr={3}
                >
                  <Link
                    underline="none"
                    onClick={() => {
                      handleOpenTermsAndConditions();
                    }}
                  >
                    <Typography className="footer-titles">{t("footer_terms_and_conditions")}</Typography>
                  </Link>
                </Grid>
                <GridDivider />
                <Grid
                  item
                  mr={3}
                >
                  <Link
                    underline="none"
                    onClick={() => {
                      handleOpenLicenseInformation();
                    }}
                  >
                    <Typography className="footer-titles">{t("footer.label.licenseinformation")}</Typography>
                  </Link>
                </Grid>
                <GridDivider />
                <Grid
                  item
                  mr={3}
                >
                  <Link
                    underline="none"
                    onClick={() => {
                      handleOpenHowToInformation();
                    }}
                  >
                    <Typography className="footer-titles">{t("footer.label.howto")}</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                md={4}
                justifyContent="flex-end"
              >
                <Typography variant="caption" fontSize="16px"><small>v.{process.env.REACT_APP_VERSION}</small></Typography>
                <Typography variant="caption" fontSize="16px" ml={1}><small>|</small></Typography>
                <Typography variant="caption" fontSize="16px" ml={1}>
                  <small>
                    {t("footer.label.need_help")}{" "}
                    <Link onClick={handleOpenContactUsDialog}>{t("footer.label.contact_us")}</Link>
                  </small>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </footer>
      <ContactUSDialog ref={contactUsDialogRef} />
    </>
  );
};

export default Footer;

import { useStoreRehydrated } from "easy-peasy";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { tokenExpiring, useAuth } from "../auth/utils";
import CountryDialog, { countryDialogRef } from "../components/Dialog/CountryLanguage/CountryDialog";
import SnackBar from "../components/SnackBar/SnackBar";
import Spinner from "../components/Spinner/Spinner";
import { useStoreActions, useStoreState } from "../hooks";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Status } from "../models/status";
import {
  designRangeConfirmationDialogRef,
} from "../pages/Configurator/FrameConfiguration/DesignRangeConfirmationDialog";
import CoverItemsFunctions from "../pages/Configurator/InsertConfiguration/Tiles/utils-cover-items";
import CoverItemsRefsFunctions from "../pages/Configurator/InsertConfiguration/Tiles/utils-cover-items-refs";
import TermsAndConditionDialog from "../pages/TermsAndCondition/TermsAndConditionDialog";
import SSOService from "../services/SSOService";
import TermsAndConditionsService from "../services/TermsAndConditionsService";
import { handleError } from "../utils/handleError";
import { ENGLISH } from "../constants/Languages";

interface IProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: FC<IProtectedRouteProps> = ({ children }) => {
  const [countryPopupOpened, SetCountryPopupOpened] = useLocalStorage("@FTS:CountryDialog", false);
  const [ termsPopupOpened, setTermsPopupOpened] = useState(false)

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isRehydrated = useStoreRehydrated();

  const [content, setContent] = useState<string>("");

  const { snackBarMessage, snackBarSeverity } = useStoreState((state) => state.app);

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { countries } = useStoreState((state) => state.countries);
  const { languages } = useStoreState((state) => state.languages);
  const { isAuthenticated, actions: auth } = useAuth();
  const { isGuest } = useStoreState((state) => state.auth);
  const { thunkGetCountries } = useStoreActions((actions) => actions.countries);
  const { thunkGetLanguages } = useStoreActions((actions) => actions.languages);
  const currentUser = useStoreState((state) => state.auth.currentUser);

  const {
    thunkGetProjectsSummary,
    thunkGetDesigns,
    thunkGetIcons,
    thunkGetIconCategories,
    thunkGetInsertTypes,
    thunkGetFrameFolds,
    setIsModifying,
    setUserDesignData,
    thunkAutoSave,
  } = useStoreActions((actions) => actions.designs);

  const { userDesignData } = useStoreState((state) => state.designs.configuration);

  const { projects, designs, isModifying, insertTypes, frameFolds, icons, iconCategories, hasSaved } = useStoreState(
    (state) => state.designs.data
  );

  useAsyncEffect(() => {
    if (!localStorage.getItem("[FTS_Customisation_Center][access_token]")) window.location.href = "/";

    async function getUserData() {
      if (projects.length == 0) {
        const projectsRes = await thunkGetProjectsSummary();
        if (projectsRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_user_data"),
            severity: "error",
          });
        }
      }
    }

    async function getAppData() {
      if (insertTypes.length == 0) {
        const insertTypesRes = await thunkGetInsertTypes();
        if (insertTypesRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_insert_types"),
            severity: "error",
          });
        }
      }

      if (frameFolds.length == 0) {
        const frameFoldsRes = await thunkGetFrameFolds();
        if (frameFoldsRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_frame_folds"),
            severity: "error",
          });
        }
      }

      if (countries.length == 0) {
        const response = await thunkGetCountries();
        if (response !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_countries"),
            severity: "error",
          });
        }
      }

      if (languages.length == 0) {
        SetCountryPopupOpened(false);
        const languagesRes = await thunkGetLanguages();
        if (languagesRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_languages"),
            severity: "error",
          });
        }
      }

      if (icons.length == 0) {
        const iconsRes = await thunkGetIcons();
        if (iconsRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_icons"),
            severity: "error",
          });
        }
      }

      if (iconCategories.length == 0) {
        const iconCategoriesRes = await thunkGetIconCategories();
        if (iconCategoriesRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_icons_categories"),
            severity: "error",
          });
        }
      }
    }

    if (isAuthenticated || isGuest) {
      getAppData();
    }

    if (isAuthenticated) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (tokenExpiring()) {
      SSOService.refreshToken()
        .then((response) => {
          auth.setAccessToken(response.data.access_token);
          auth.setRefreshToken(response.data.refresh_token);
        })
        .catch(() => { });
    }
  }, [navigate]);

  useEffect(() => {
    if (!["/frame-configuration", "/insert-configuration", "/design-preview", "/design-summary"].includes(location.pathname)) {
      CoverItemsRefsFunctions.removeAll();
      CoverItemsFunctions.removeAll();
      setIsModifying(false);

      if (userDesignData != null && !hasSaved) {
        thunkAutoSave(userDesignData!);
      }
    }

    return () => {
      window.onpopstate = (event: PopStateEvent) => {
        const currentPathName = event.currentTarget!["location"].pathname;
        if (currentPathName == "/start-configurator") {
          event.stopImmediatePropagation();
          designRangeConfirmationDialogRef.current?.open();
        }
      };
    };
  }, [location.pathname]);

  useEffect(() => {
    async function getDesignData() {
      if (designs.length == 0) {
        const designsRes = await thunkGetDesigns(currentUser?.countryId!);
        if (designsRes !== Status.COMPLETED) {
          thunkOpenSnackBar({
            message: t("protected_route.error_message.get_designs"),
            severity: "error",
          });
        }
      }
    }

    if ((isAuthenticated || isGuest) && currentUser?.countryId && currentUser?.countryId > 0) {
      getDesignData();
    }

    if (currentUser?.countryId == 0) {
      SetCountryPopupOpened(true);
      countryDialogRef.current?.open();
    } else if (!currentUser?.termsAndConditionsAccepted) {
      setTermsPopupOpened(true)
    }
  }, [currentUser]);

  useEffect(() => {
    if (languages.length > 0) {
      initialTermsAndConditions();
    }
  }, [languages]);

  const initialTermsAndConditions = () => {
    const currentLanguageCode = localStorage.getItem("lang") == "en" ? ENGLISH : localStorage.getItem("lang");
    let languageId = languages.find((x) => x.code == currentLanguageCode)?.id!;

    if (!languageId) languageId = currentUser?.languageId!;

    TermsAndConditionsService.get(languageId)
      .then((response) => {
        setContent(response.data.content);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <>
      {(!isRehydrated && <Spinner />) || children}

      <CountryDialog
        ref={countryDialogRef}
        currentUser={currentUser!}
        countries={countries}
        languages={languages}
      />

      {content && (
        <TermsAndConditionDialog
          popupOpen={termsPopupOpened}
          content={content}
          currentUser={currentUser!}
        />
      )}

      <SnackBar
        message={snackBarMessage}
        severity={snackBarSeverity}
      />

    </>
  );
};

export default ProtectedRoute;

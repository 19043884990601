import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import CloseIcon from "../../pages/Configurator/Icons/close.svg";
import { IDialogHandle } from "../../interfaces/IDialogHandle";
import './Checkout.css';
import { useStoreActions } from "../../hooks";

export const checkoutDialogRef = createRef<IDialogHandle>();

interface ICheckoutDialogProps {
  // elBridge: IProductDesignELBridge[];
}

const CheckoutDialog: ForwardRefRenderFunction<IDialogHandle, ICheckoutDialogProps> = (props, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { thunkOpenSnackBar } = useStoreActions(actions => actions.app);

  const callELBridge = async () => {
    try {
      var form = document.createElement("form");
      form.target = "elBridge";
      form.method = "POST";
      form.action = process.env.REACT_APP_ELBRIDGE_URL!;
      var params = {
        version: "2.0",
        result: {
          ITEM: [
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "2CKA006200A0154",
              QUANTITY: "1",
              ORDER_UNIT: "C62",
            },
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "2CDG110150R0011",
              QUANTITY: "1",
              ORDER_UNIT: "C62",
            },
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "2CDG280001R0011",
              QUANTITY: "1",
              ORDER_UNIT: "C62",
            },
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "GHV9270001V0001",
              QUANTITY: "1",
              ORDER_UNIT: "C62",
            },
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "GHQ3050017R0001",
              QUANTITY: "1",
              ORDER_UNIT: "C62",
            },
            {
              SUPPLIER_ID_GLN: "4011395000007",
              MANUFACTURER_PID: "GHV9240001V0013",
              QUANTITY: "2",
              ORDER_UNIT: "C62",
            },
          ],
        },
      };

      for (var i in params) {
        if (params.hasOwnProperty(i)) {
          var input = document.createElement("input");
          input.type = "hidden";
          input.name = i;
          input.value =
            i === "version"
              ? params[i]
              : JSON.stringify(params[i as keyof typeof params]);
          form.appendChild(input);
        }
      }
      document.body.appendChild(form);
      form.submit();
      window.open("", "elBridge");
    } catch (err: any) {
      thunkOpenSnackBar({
        message: `An error occurred : ${err.message}`,
        severity: 'error'
      })
    }
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose
    };
  })

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        aria-label="close"
        onClick={() => { checkoutDialogRef.current?.close() }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          width: 35,
          height: 35
        }}
      >
        <ReactSVG
          onClick={() => { checkoutDialogRef.current?.open() }}
          src={CloseIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 24px; height: 24px; fill: #009EE2; padding: 0px; margin-top:5px`
            );
          }}
        />
      </IconButton>
      <DialogTitle className="checkoutDialog__title">{t("Checkout_CheckoutDialog_Title")}</DialogTitle>
      <DialogContent className="checkoutDialogContent__main">
        <DialogContentText className="checkoutDialogContent__text">
          {t("Checkout_CheckoutDialog_TextContent")}
        </DialogContentText>
        <div className="checkout__btnContainer">
          {/* <Button className="checkout__payOneBtn" variant="contained">
                {t("Checkout_CheckoutDialog_PayOneButton")}
              </Button> */}
          <Button
            onClick={() => callELBridge()}
            className="checkout__distributorBtn"
            variant="outlined"
          >
            {t("Checkout_CheckoutDialog_FindDistributorButton")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default forwardRef(CheckoutDialog);
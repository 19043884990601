import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { ILanguage } from "../interfaces/ILanguage";

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axiosInstance.get(url).then(responseBody),
};

const LanguageService = {
  get: (): Promise<ILanguage[]> => requests.get("languages"),
};

export default LanguageService;

import { Grid, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import InfoIcon from "../../../pages/Configurator/Icons/info3.svg";
import { useTranslation } from "react-i18next";

const IconDialogInfoBox: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        className="iconDialog__info_description"
      >
        <div className="iconDialog__info_icon">
          <ReactSVG
            src={InfoIcon}
            style={{ paddingTop: "3px", paddingRight: "5px" }}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `fill: #1B7AC2; `
              );
            }}
          />
          <Typography sx={{ fontSize: "14px" }}>{t("icon_dialog.function_info")}</Typography>
        </div>
      </Grid>
    </>
  );
};

export default IconDialogInfoBox;

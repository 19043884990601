import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ForwardRefRenderFunction, createRef, forwardRef, useImperativeHandle, useState } from "react";
import Grid from "@mui/material/Grid";
import "./CountryDialog.css";
import { ILoggedUser } from "../../../interfaces/DTO/ILoggedUser";
import { ICountry } from "../../../interfaces/ICountry";
import { ILanguage } from "../../../interfaces/ILanguage";
import { IDialogHandle } from "../../../interfaces/IDialogHandle";
import Content from "./Content";
import { useTranslation } from "react-i18next";

interface ICountryDialogProps {
  currentUser: ILoggedUser;
  countries: ICountry[];
  languages: ILanguage[];
}

export const countryDialogRef = createRef<IDialogHandle>();

const CountryDialog: ForwardRefRenderFunction<IDialogHandle, ICountryDialogProps> = (props, ref) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      PaperProps={{ className: "dialog-container" }}
    >
      <DialogTitle className="dialog-title-container">
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <span className="dialog-title">{t("Language_Dialog_Title")}</span>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog-content-container">
        <Content
          currentUser={props.currentUser}
          countries={props.countries}
          languages={props.languages}
        />
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(CountryDialog);

import { Grid, IconButton, useTheme } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import { useStoreState } from "../../../../hooks";
import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { ITileDimension } from "../../../../interfaces/ITileDimension";
import { IRockerTextLabelProps, isHorizontal, ITextLabelSizes, LimitChar } from "./limitChar";
import { ABB } from "../../../../constants/Theme";
import { FTSTheme } from "../../../../App";
import EditIcon from "../../Icons/EditIcon.svg";

interface ITilePreviewConfigurationProps {
  cover: number;
  insertDetail: IInsertDetail;
  indexDetail: string;
  isRocker: boolean;
  tileDimension: ITileDimension;
  tileName?: string;
  textLabelSizes?: ITextLabelSizes | IRockerTextLabelProps;
  designCode?: string;
}

const pxINmm = 3.7795275591;

const TilePreviewConfiguration: React.FC<ITilePreviewConfigurationProps> = (props) => {
  const theme = useTheme() as FTSTheme;
  
  const { icons } = useStoreState((state) => state.designs.data);

  const insertDetail = props.insertDetail!;

  const icon = icons.find(
    (x) =>
      x.id ===
      insertDetail.items.find((x) => x.type == EInsertDetailItemType.Icon && x.indexDetail == props.indexDetail)?.iconId
  );

  const iconSize =
    insertDetail.items.find((icon) => icon.type == EInsertDetailItemType.Icon && icon.indexDetail == props.indexDetail)
      ?.dimension?.width! * pxINmm;

  const iconColor = props.tileDimension.isFunction
    ? insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
    : insertDetail?.insertColor?.primaryPrintColor?.hexColor!;

  const textField =
    props.insertDetail.items.find(
      (item) => item.type == EInsertDetailItemType.Text && item.indexDetail == props.indexDetail
    )?.textValue ?? "";

  const fontSize = insertDetail.items.find(
    (item) => item.type == EInsertDetailItemType.Text && item.indexDetail == props.indexDetail
  )?.font?.size;

  const getRotate = () => {
    const rotate = props.insertDetail.rotate ?? 0;

    if (rotate == 90) {
      return 270;
    }
    if (rotate == 180) {
      return 180;
    }
    if (rotate == 270) {
      return 90;
    }

    return 0;
  };

  const getNewWidth = (insertDetail: IInsertDetail, newRotate?: number) => {
    const textFieldProps = LimitChar.get(props.designCode!)!;
    if (newRotate == null && (insertDetail.rotate == null || insertDetail.rotate == 0))
      return props.textLabelSizes!.canvas?.width;

    const rotate = newRotate ?? insertDetail.rotate!;

    if (props.isRocker) {
      return isHorizontal(rotate)
        ? textFieldProps.horizontal[props.tileName!].rocker.canvas.width
        : textFieldProps.vertical[props.tileName!].rocker.canvas.width;
    }

    return isHorizontal(rotate)
      ? textFieldProps.horizontal[props.tileName!].canvas.width
      : textFieldProps.vertical[props.tileName!].canvas.width;
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <div
          className="box-icon"
          style={{
            fontFamily: `${theme.name == ABB ? 'ABBvoice' : 'BJEAverta' }`,
            transform: `rotate(${getRotate()}deg)`,
            width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
          }}
        >
          {(icon?.blobUrl) && (
            <IconButton
              disabled
              className={`${
                props.tileDimension.isFunction
                  ? `${theme.name}-border-icon-function-with-canvas-preview`
                  : `${theme.name}-border-icon-control-with-canvas-preview`
              }`}              
            >
              <ReactSVG
                src={icon?.blobUrl ?? EditIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: ${iconSize}px; height: ${iconSize}px; fill: ${icon?.blobUrl ? iconColor : 'transparent'}; display: flex;`
                  );
                }}
              />
            </IconButton>
          )}

          <div
          className={`tile-div-canvas-preview${!props.tileName?.toLowerCase().includes("slider") && !icon?.blobUrl ? '-no-icon': ''} ${props.tileDimension.isFunction ? "function" : props.isRocker ? "button-rocker" : "default"}`}
            style={{
              color: props.tileDimension.isFunction
                ? insertDetail?.insertColor?.secondaryPrintColor?.hexColor!
                : insertDetail?.insertColor?.primaryPrintColor?.hexColor!,
              fontSize: `${fontSize}pt`,
              whiteSpace: "pre",
              textAlign: "center",
              lineHeight: `${fontSize! + 3}pt`,
              fontWeight: "500",
              height: `${fontSize! * textField?.split("\n").length! + (textField?.split("\n").length! == 1 ? 4 : 6)}px`,
              width: `${getNewWidth(props.insertDetail, props.insertDetail.rotate!)}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {textField}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default TilePreviewConfiguration;

import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import "./ConfiguratorStart.css";
import { Typography } from "@mui/material";
import { MainLayout } from "../../components/Layout";
import { NavbarNew } from "../../components/Navbar";
import ConfiguratorStartContent from "./ConfiguratorStartContent";
import { configuratorWelcomeDialogRef } from "./Components/WelcomeModal/ConfiguratorDialog/ConfiguratorWelcomeDialog";
import useAppTitle from "../../hooks/useAppTitle";

interface ILocationStateType {
  isNewProject: boolean;
}

const ConfiguratorStart: FC<{}> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationState = useLocation().state as ILocationStateType;
  const [isNewProject] = useState<boolean>(locationState.isNewProject);
  const [, setScreenWidth] = useState<number>(window.innerWidth);
  const appTitle = useAppTitle()
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { setUserDesignData, thunkAutoSave } = useStoreActions((actions) => actions.designs);
  const { frameFolds } = useStoreState((state) => state.designs.data);

  useEffect(() => {
    resetProject();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!isNewProject && userDesignData == null) {
      navigate("/dashboard", { replace: true });
    }

    if (isNewProject) {
      configuratorWelcomeDialogRef.current?.open();
    }
  }, [isNewProject]);

  const resetProject = () => {
    if (userDesignData?.userCustomDesign.insertDetails?.length! > 0) {
      const newValues = Object.assign(userDesignData!, {
        numberOfSwitches: 1,
        userCustomDesign: Object.assign(userDesignData?.userCustomDesign!, {
          isHorizontal: true,
          frameFold: frameFolds.find((f) => parseInt(f.frameCode) == 1),
          insertDetails: userDesignData?.userCustomDesign.insertDetails.slice(0, 1),
          pathname: "/start-configurator",
          room: "",
          location: ""
        }),
      });
      if (!isNewProject) {
        thunkAutoSave(newValues);
        setUserDesignData(newValues);
      }
    }
  };

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <>
      <MainLayout.Root>
        <NavbarNew.Root>
          <div className="navbar__logoTitleContainer">
            <NavbarNew.Logo link="/" />
            <NavbarNew.Title title={appTitle} />
          </div>
          <NavbarNew.Links>
            <NavbarNew.Link
              translationKey="dashboard"
              path="/dashboard"
            />
            <NavbarNew.Link
              translationKey="myProjects"
              path="/projects"
            />
            {/*<NavbarNew.Link*/}
            {/*  translationKey="favoriteDesigns"*/}
            {/*  path="/favoriteDesigns"*/}
            {/*/>*/}
            <div className="link__separator"></div>
            <Link
              to="/checkout"
              className="shoppingCart__link"
            >
              {/* <ShoppingCartIcon
                style={{ fill: "#464646" }}
                fontSize="medium"
              /> */}
              <Typography variant="body1">{t("cart")}</Typography>
            </Link>
            <NavbarNew.User />
          </NavbarNew.Links>
        </NavbarNew.Root>

        <MainLayout.Content>
          <ConfiguratorStartContent />
        </MainLayout.Content>
      </MainLayout.Root>
    </>
  );
};

export default ConfiguratorStart;

import { Button, ButtonProps, CircularProgress, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";
import LoadingButton from "@mui/lab/LoadingButton";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  disabled: boolean;
  handleEdit: () => Promise<void>;
}

const AbbSave: React.FC<IButtonProps> = ({ loading, disabled, handleEdit }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      sx={{
        width: "95px",
        height: "40px",
      }}
      onClick={handleEdit}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      disabled={disabled}
      variant="contained"
    >
      <span>{t("save")}</span>
    </LoadingButton>
  );
};

const BjeSave: React.FC<IButtonProps> = ({ loading, disabled, handleEdit }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      sx={{
        width: "193px",
        height: "41px",
      }}
      onClick={handleEdit}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      disabled={disabled}
      variant="contained"
    >
      <span>{t("save")}</span>
    </LoadingButton>
  );
};

const SaveButton: React.FC<IButtonProps> = ({ ...rest }) => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbSave {...rest} />) || (theme.name == BJE && <BjeSave {...rest} />)}</>;
};

export default SaveButton;

import { FC, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18n";
import appRoutes from "./routes/Routes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import React from "react";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { Button, CustomThemeOptions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppConfirm from "./components/Confirm/Confirm";
import { BackdropProvider } from "./hooks/backdrop";
import { useTranslation } from "react-i18next";
import { useStoreState } from "./hooks";
import { ABB } from "./constants/Theme";
import { COUNTRIES_CODES } from "./constants/Languages";

export interface FTSTheme extends CustomThemeOptions {
  name: string;
  icon: {
    color: string;
  };
}

const bjeTheme: FTSTheme = {
  name: "bje",
  icon: {
    color: "#009fe3",
  },
  palette: {
    primary: {
      main: "#009fe3",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "BJEAverta",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  overrides: {
    MuiAutocomplete: {
      popup: { zIndex: 1300 },
    },
  },
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          textTransform: "none",
          // textOverflow: 'ellipsis',
          // whiteSpace: 'nowrap',
          // overflow: 'hidden',
          // width: '100%'
        },
        text: {
          // textOverflow: 'ellipsis',
          // whiteSpace: 'nowrap',
          // overflow: 'hidden',
          // width: '50px'
        }
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#000",
          borderRadius: "4px",
          border: "1px solid var(--primary-color-grey-10, #EBEBEB)",
          background: "#FFF",
          boxShadow: "0px 2px 10px 0px rgba(128, 128, 128, 0.10), 0px 4px 15px 0px rgba(214, 214, 214, 0.25)",
        },
        arrow: {
          color: "#FFF",
        },
      },
    },
  },
};

const abbTheme: FTSTheme = {
  name: "abb",
  icon: {
    color: "#0F0F0F",
  },

  palette: {
    primary: {
      main: "#E12E2E",
    },
  },
  typography: {
    fontFamily: `"ABBVoice"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  overrides: {
    MuiAutocomplete: {
      popup: { zIndex: 1300 },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "100px",
          minWidth: "5px",
          // textOverflow: 'ellipsis',
          // whiteSpace: 'nowrap',
          // overflow: 'hidden',          
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: "#E12E2E",
          color: "#E12E2E",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#000",
          borderRadius: "4px",
          border: "1px solid var(--primary-color-grey-10, #EBEBEB)",
          background: "#FFF",
          boxShadow: "0px 2px 10px 0px rgba(128, 128, 128, 0.10), 0px 4px 15px 0px rgba(214, 214, 214, 0.25)",
        },
        arrow: {
          color: "#FFF",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#000000',
            },
            '&:hover fieldset': {
              borderColor: '#000000',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#000000',
            },
          },
        },
      }
    }
  },
};

const bjeMuiTheme = createTheme(bjeTheme);
const abbMuiTheme = createTheme(abbTheme);

const App: FC<{}> = (props) => {
  const [selectedTheme, setSelectedTheme] = useState(process.env.REACT_APP_THEME);
  const notistackRef = React.createRef<SnackbarProvider>();
  const { t } = useTranslation();
  const { currentUser } = useStoreState((state) => state.auth)
  const isSwitzerland = currentUser?.countryId === COUNTRIES_CODES.Switzerland

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as any;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `./${process.env.REACT_APP_THEME}-favicon.svg`;
  }, []);

  useEffect(() => {
    document.title = isSwitzerland && selectedTheme === ABB ? t(`abb.customization_center.switzerland`) : t(`${selectedTheme}.document.title`);
  }, [t, selectedTheme])

  const onClickDismiss = (key: SnackbarKey) => {
    notistackRef!.current!.closeSnackbar(key);
  };

  const toggleTheme = () => {
    setSelectedTheme((prev) => (prev == "bje" ? "abb" : "bje"));
  };

  return (
    <>
      <ThemeProvider theme={selectedTheme == "bje" ? bjeMuiTheme : abbMuiTheme}>
        <BackdropProvider>
          <SnackbarProvider
            style={{
              backgroundColor: "#0CA919",
              color: "#FFFFFF",
            }}
            hideIconVariant
            ref={notistackRef}
            maxSnack={4}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            action={(key: SnackbarKey) => (
              <Button
                color="inherit"
                size="small"
                onClick={() => onClickDismiss(key)}
              >
                <CloseIcon />
              </Button>
            )}
          >
            {/* <button
              style={{ position: "fixed", bottom: "0", zIndex: "999" }}
              onClick={toggleTheme}
            >
              {selectedTheme == "bje" ? "abb" : "bje"}
            </button> */}
            <Router>
              <Routes>
                {appRoutes.map((item, index) => (
                  <Route
                    key={index}
                    path={item.path}
                    element={item.element}
                  />
                ))}
              </Routes>
            </Router>
            <AppConfirm />
          </SnackbarProvider>
        </BackdropProvider>
      </ThemeProvider>
    </>
  );
};

export default App;

import { Button, ButtonProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";
import { editDialogRef } from "./EditDialog";

interface IButtonProps extends ButtonProps {}

const AbbCancel: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        width: "109px",
        height: "40px",
      }}
      onClick={() => {
        editDialogRef.current?.close();
      }}
      className="design-preview-dialog-btn-cancel"
    >
      {t("cancel")}
    </Button>
  );
};

const BjeCancel: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => {
        editDialogRef.current?.close();
      }}
      className="design-preview-dialog-btn-cancel"
    >
      {t("cancel")}
    </Button>
  );
};

const CancelButton: React.FC<IButtonProps> = ({ ...rest }) => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbCancel {...rest} />) || (theme.name == BJE && <BjeCancel {...rest} />)}</>;
};

export default CancelButton;

export const getCurrentScreenWidth = () => {
  return window.innerWidth;
};

export const getCurrentScreenHeight = () => {
  return window.innerHeight;
};

export enum ScreenSize {
  MOBILE = 480,
  TABLET = 768,
  TABLETM = 896,
  LAPTOP = 1024,
  LAPTOPM = 1195,
  DESKTOP = 1366,
  DESKTOPM = 1617,
  LARGESCREEN = 1867,
  HDSCREEN = 1920,
}

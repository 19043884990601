export const hasWhiteSpace = (s: string) => {
  return /\s/.test(s);
};

export const truncate = (source: string, size: number) => {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
};

export const capitalizeFirstLowercaseRest = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateRandomProductDesignName = () => {
  const randomString = generateRandomString(5);
  return `Design-${randomString}`;
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { ENGLISH } from "./constants/Languages";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "lang",
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/languages/languagetranslationbycode/{{lng}}`,
    },
    debug: false,
    fallbackLng: ENGLISH,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { FC } from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useStoreActions, useStoreState } from "../../hooks";

export interface ISnackBarComponentProps {
  message: string;
  severity: AlertColor;
}

const SnackBar: FC<ISnackBarComponentProps> = ({ message, severity }) => {
  const { showSnackBar } = useStoreState((state) => state.app);
  const { setShowSnackBar } = useStoreActions((actions) => actions.app);

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  return (
    <Snackbar
      open={showSnackBar}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;

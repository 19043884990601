import Menu from "@mui/material/Menu";
import { Link as LinkMui } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../auth/utils";
import { Grid, Paper } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Navbar.css";
import { useState } from "react";
import store from "../../store";
import { getSignOutHref } from "../../auth/config";
import logging from "../../config/logging";
import { useStoreState } from "../../hooks";
import { useNavigate } from "react-router-dom";

interface INavbarUserProps {}

export function NavbarUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAuthenticated, user, actions: auth } = useAuth();
  const { isGuest } = useStoreState((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    store.persist
      .clear()
      .then(() => {
        auth.removeAllData();
        window.location.replace(getSignOutHref());
      })
      .catch((err) => logging.error(err));
  };

  return (
    <>
      <div className="navbar__user">
        {isAuthenticated && (
          <>
            <IconButton
              id="navbar__userMenuBtn"
              aria-controls={open ? "navbar__userMenu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: 14,
                  lineHeight: 18,
                  bgcolor: "#EDEDED",
                  color: "#000000",
                  fontWeight: 700,
                }}
              >
                {isAuthenticated && !isGuest && user != null
                  ? user.firstname[0].toUpperCase() + user.lastname[0].toUpperCase()
                  : " G"}
              </Avatar>
              {(open && <ExpandLessIcon />) || <ExpandMoreIcon />}
            </IconButton>
            <Menu
              id="navbar__userMenu"
              aria-labelledby="navbar__userMenuBtn"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "325px",
                  height: "149px",
                }}
              >
                <Grid
                  container
                  p={"22px 18px"}
                >
                  <Grid
                    item
                    md={4}
                  >
                    <Avatar
                      sx={{
                        width: 68,
                        height: 68,
                        fontSize: 24,
                        lineHeight: 18,
                        bgcolor: "#EDEDED",
                        color: "#000000",
                        fontWeight: 400,
                      }}
                    >
                      {isAuthenticated && !isGuest && user != null
                        ? user.firstname[0].toUpperCase() + user.lastname[0].toUpperCase()
                        : " G"}
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    md={8}
                  >
                    <Typography
                      variant="h6"
                      fontSize={"16px"}
                    >
                      {user != null ? `${user.firstname} ${user.lastname}` : " G"}
                    </Typography>
                    {user != null && (
                      <>
                        <Typography
                          variant="body1"
                          fontSize={"13px"}
                        >
                          {user.email}
                        </Typography>
                        <LinkMui
                          onClick={() => navigate("/profile")}
                          sx={{ cursor: "pointer", textDecoration: "none" }}
                        >
                          <Typography
                            sx={{ textDecoration: "underline" }}
                            mt={"20px"}
                            color="InfoText"
                            variant="body1"
                            fontSize={"13px"}
                          >
                            {t("accountSettings")}
                          </Typography>
                        </LinkMui>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                onClick={handleSignOut}
                elevation={0}
                sx={{
                  backgroundColor: "#D9D9D9",
                  cursor: "pointer",
                  textAlign: "center",
                  borderTopLeftRadius: "0",
                  borderTopRightRadius: "0",
                }}
              >
                <Grid
                  sx={{ height: "42px" }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography>{t("navbar.user_menu.label.signout")}</Typography>
                </Grid>
              </Paper>
            </Menu>
          </>
        )}
      </div>
    </>
  );
}

import { FormControlLabel, Switch, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../../hooks";

import "../InsertConfiguration.css";
import "./layout-configuration.css";

interface IProps {
  allow: boolean;
  enable: boolean;
}

const GridLineSwitch: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(props.enable);
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { setUserDesignData } = useStoreActions((actions) => actions.designs);

  useEffect(() => {
    setChecked(props.enable);
  }, [props.enable]);

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map((detail) => {
      detail.hasGridLine = event.target.checked;
      return detail;
    });

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);
  };

  return (
    <div className="insertConfiguration__gridLineSwitch">
      <FormControlLabel
        label={t("InsertConfiguration_GridLineLabel")}
        disabled={props.allow === false}
        sx={{ color: "#1F1F1F" }}
        control={
          <Switch
            sx={{
              ".MuiSwitch-track": {
                backgroundColor: props.allow && props.enable ? "" : "black",
              },
            }}
            disabled={props.allow === false}
            onChange={handleChangeAll}
            checked={props.allow == false ? false : checked}
            size="small"
          />
        }
      />
    </div>
  );
};

export default GridLineSwitch;

import { ButtonProps, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../../App";
import { ABB, BJE } from "../../../../../constants/Theme";
import { LoadingButton } from "@mui/lab";
import FitText from "../../../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  disabled: boolean;
  handleCreateProject: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const AbbConfirm: React.FC<IButtonProps> = ({ loading, disabled, handleCreateProject }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <LoadingButton
      sx={{ width: 119, height: 40 }}
      variant="contained"
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      disabled={disabled}
      onClick={handleCreateProject}
    >
      <FitText sx={{ fontSize: 14, fontWeight: 500 }}>{t(`configurator_welcome_dialog.button.start.title`)}</FitText>
    </LoadingButton>
  );
};

const BjeConfirm: React.FC<IButtonProps> = ({ loading, disabled, handleCreateProject }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <LoadingButton
      sx={{ width: 193, height: 41 }}
      variant="contained"
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      disabled={disabled}
      onClick={handleCreateProject}
    >
      <FitText>{t(`configurator_welcome_dialog.button.start.title`)}</FitText>
    </LoadingButton>
  );
};

const ConfirmButton: React.FC<IButtonProps> = ({ loading, disabled, handleCreateProject }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbConfirm
          loading={loading}
          disabled={disabled}
          handleCreateProject={handleCreateProject}
        />
      )) ||
        (theme.name == BJE && (
          <BjeConfirm
            loading={loading}
            disabled={disabled}
            handleCreateProject={handleCreateProject}
          />
        ))}
    </>
  );
};

export default ConfirmButton;

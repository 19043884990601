import { EDesignStatus } from "../../../enum/EDesignStatus";
import { IInsertDetail, IInsertDetailRequest } from "../../IInsertDetail";
import { IUserDesignData } from "../../IUserDesignData";

export interface IProductDesignUpdateRequest {
  id: string;
  name?: string;
  isArchived: boolean;
  designId: string;
  projectId: string;
  frameFoldId: string;
  frameColorId: string;
  isHorizontal?: boolean;
  pathname: string;
  designStatus?: EDesignStatus;
  insertDetails: IInsertDetailRequest[];
  quantity: number;
  room: string;
  location: string
}

export const userDataToProductDesignRequest = (payload: IUserDesignData) => {

  const request: IProductDesignUpdateRequest = {
    id: payload.userCustomDesign.id!,
    name: payload.userCustomDesign.name!,
    isArchived: payload.userCustomDesign.isArchived!,
    designId: payload.userCustomDesign.designId!,
    projectId: payload.userCustomDesign.projectId!,
    frameFoldId: payload.userCustomDesign.frameFold?.id!,
    frameColorId: payload.userCustomDesign.frameColor?.id!,
    isHorizontal: payload.userCustomDesign.isHorizontal!,
    pathname: payload.userCustomDesign.pathname!,
    designStatus: payload.userCustomDesign.designStatus!,
    insertDetails: payload.userCustomDesign.insertDetails.map(insertDetailToRequest),
    quantity: payload.userCustomDesign.quantity!,
    room: payload.userCustomDesign.room || "",
    location: payload.userCustomDesign.location || ""
  };
  return request;
};

const insertDetailToRequest = (detail: IInsertDetail) => {
  if (detail == null) return detail;

  const insertDetailRequest: IInsertDetailRequest = {
    id: detail.id,
    insertTypeId: detail.insertType?.id,
    insertColorId: detail.insertColor?.id,
    order: detail.order!,
    coverDesignId: detail.coverDesignId,
    rotate: detail.rotate,
    hasGridLine: detail.hasGridLine,
    isFlipped: detail.isFlipped,
    items: detail.items.filter((item) => !item.gridLine || detail.hasGridLine),
  };

  return insertDetailRequest;
};

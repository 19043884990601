import { Button, ButtonProps, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import { ReactComponent as HelpIcon } from "../Icons/Info.svg";

interface IButtonProps extends ButtonProps {}

const AbbHelpCustomizationButton: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t("frame_configuration.tooltip.need_help_with_customization")}
      placement="top-start"
      slotProps={{
        tooltip: {
          style: {
            fontSize: "12px",
            fontWeight: 400,
            padding: "8px 12px",
          },
          width: "265px",
          height: "58px",
        },
      }}
    >
      <Typography
        sx={{
          display: "flex",
          gap: "8px",
          fontSize: "16px",
          fontWeight: 400,
          color: "#696969",
        }}
      >
        <HelpIcon
          fill="#696969"
          width={24}
          height={24}
          viewBox="0 0 16 16"
        />
        {t("frame_configuration.button.need_help_with_customization")}
      </Typography>
    </Tooltip>
  );
};

const BjeHelpCustomizationButton: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t("frame_configuration.tooltip.need_help_with_customization")}
      placement="top-start"
      slotProps={{
        tooltip: {
          style: {
            fontSize: "12px",
            fontWeight: 400,
            padding: "8px 12px",
          },
          width: "265px",
          height: "58px",
        },
      }}
    >
      <Typography
        sx={{
          display: "flex",
          gap: "8px",
          fontSize: "16px",
          fontWeight: 400,
          color: "#009EE2",
        }}
      >
        <HelpIcon
          fill="#009EE2"
          width={24}
          height={24}
          viewBox="0 0 16 16"
          fontSize={24}
        />
        {t("frame_configuration.button.need_help_with_customization")}
      </Typography>
    </Tooltip>
  );
};

const HelpCustomizationButton: React.FC<IButtonProps> = () => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbHelpCustomizationButton />) || (theme.name == BJE && <BjeHelpCustomizationButton />)}
    </>
  );
};

export default HelpCustomizationButton;

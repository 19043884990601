import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { FTSTheme } from "../../App";
import { ABB, BJE } from "../../constants/Theme";
import { useTranslation } from "react-i18next";
import ArrowRight from "../../Icons/arrow-right-icon.svg";
import { ReactSVG } from "react-svg";
import FitText from "../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {}

const AbbStartConfiguration: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      sx={{ width: 236, height: 44 }}
    >
      <FitText sx={{ fontSize: 16, fontWeight: 500 }}>{t("dashboard.button.start_configuration")}</FitText>
      <ReactSVG
        src={ArrowRight}
        beforeInjection={(svg) => {
          svg.setAttribute("style", "margin-left: 5px");
        }}
      />
    </Button>
  );
};

const BjeStartConfiguration: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      sx={{ width: 216, height: 44 }}
    >
      <FitText sx={{ fontSize: 16, fontWeight: 700 }}>{t("dashboard.button.start_configuration")}</FitText>
      <ReactSVG
        src={ArrowRight}
        beforeInjection={(svg) => {
          svg.setAttribute("style", "margin-left: 5px");
        }}
      />
    </Button>
  );
};

const StartConfigurationButton: React.FC = () => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbStartConfiguration />) || (theme.name == BJE && <BjeStartConfiguration />)}</>;
};

export default StartConfigurationButton;

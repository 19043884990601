import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../../../hooks";
import { FrameStyleProps } from "../../../../interfaces/IFrameAndCoverStyle";
import { IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { isHorizontal } from "../../InsertConfiguration/Tiles/limitChar";
import "./Cover.css";

interface ICoverInputProps extends HTMLAttributes<HTMLDivElement> {
  cover: number;
  showMessageOnImageNotFound?: boolean;
  designCode: string;
  isHorizontal: boolean;
  insertDetail: IInsertDetail;
  coverRefs?: any;
}

const Cover: React.FC<ICoverInputProps> = (props) => {
  const { cover, showMessageOnImageNotFound: showMessageOnNotFoundImage, children, insertDetail, ...rest } = props;

  const { t } = useTranslation();

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const coverCode = insertDetail.insertColor?.code;

  const getBackgroundUrl = () => {
    const source = `${process.env.REACT_APP_BLOB_STORAGE_URL}/images/inserts/${coverCode}/img.png`;

    var img = new Image();
    img.src = source;
    img.onerror = () => {
      if (showMessageOnNotFoundImage!) {
        thunkOpenSnackBar({
          message: t("cover.get_background_url.message"),
          severity: "info",
        });
        return;
      }
    };

    return `url(${source})`;
  };

  const getBackgroundImageProps = () => {
    const designCode = props.designCode;
    return {
      backgroundImage: getBackgroundUrl(),
      backgroundSize: FrameStyleProps.get(designCode)?.cover.backgroundSize,
    };
  };

  const getCoverStyleDefaultProps = () => {
    const designCode = props.designCode;
    return {
      backgroundImage: getBackgroundUrl(),
      backgroundSize: FrameStyleProps.get(designCode)?.cover.backgroundSize,
      width: FrameStyleProps.get(designCode)?.cover.width,
      height: FrameStyleProps.get(designCode)?.cover.height,
    };
  };

  const getCoverItemStyleProps = () => {
    const designCode = props.designCode;
    return {
      marginTop: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginTop,
      marginRight: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginRight,
      marginBottom: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginBottom,
      marginLeft: FrameStyleProps.get(designCode)?.cover.coverItems[cover].marginLeft,
    };
  };

  const getFutureDesignOrientation = () => {
    if (props.isHorizontal) return { transform: "rotate(0deg)" };

    return { transform: "rotate(270deg)" };
  };

  const getTransformProps = () => {
    return `rotate(${insertDetail?.rotate ?? 0}deg)`;
  };

  return (
    <>
      {insertDetail && (
        <div
          {...rest}
          className={`
          frame-cover 
          frame-cover-${cover}`}
          style={{
            ...getCoverStyleDefaultProps(),
            ...getCoverItemStyleProps(),
            ...getFutureDesignOrientation(),
          }}
        >
          {/* keep cover rotation when based on frame orientation */}
          {/* style={{ transform: `${!props.isHorizontal! ? 'rotate(270deg)' : 'rotate(0deg)'}`, }} */}
          <div
            data-coverdesignid={insertDetail.coverDesignId}
            ref={(ref) => {
              if (props.coverRefs) props.coverRefs.current[props.cover] = ref;
            }}
            style={{
              ...getBackgroundImageProps(),
            }}
          >
            {/* change cover rotation based on rotate button */}
            <div
              style={{
                transform: getTransformProps(),
              }}
              data-coverorientation={isHorizontal(insertDetail!.rotate ?? 0) ? "horizontal" : "vertical"}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cover;

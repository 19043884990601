import { Accordion, AccordionSummary, Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import { useStoreActions, useStoreState } from "../../hooks";
import AddProjectIcon from "../Configurator/Icons/AddProject.svg";
import ArrowDownIcon from "../Configurator/Icons/arrow-down.svg";
import { ReactComponent as ArrowRightIcon } from "../Configurator/Icons/arrow-right.svg";
import "./Projects.css";
import ProjectsTable from "./ProjectsTable";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { FTSTheme } from "../../App";
import BackButton from "../../components/BackButton/BackButton";
import { ABB } from "../../constants/Theme";
import ProfileImg from "../../images/Profile.png";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import FitText from "../../components/FitText/FitText";
import useAppTitle from "../../hooks/useAppTitle";

const Projects: FC<{}> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ftsTheme = useTheme() as FTSTheme;

  const { currentUser } = useStoreState((state) => state.auth);
  const { projectsSummary, designs } = useStoreState((state) => state.designs.data);
  const { setUserDesignDataFromDesign, setSelectedProjectsSummary } = useStoreActions((actions) => actions.designs);
  const { thunkGetProjectsSummary } = useStoreActions((actions) => actions.designs);
  const [availableProjectsSummary, setAvailableProjectsSummary] = useState<IProjectSummaryDto[]>([]);
  const [unavailableProjectsSummary, setUnAvailableProjectsSummary] = useState<IProjectSummaryDto[]>([]);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const [checked, setChecked] = useState<string[]>([]);
  const appTitle = useAppTitle();

  useEffect(() => {
    if (projectsSummary.length > 0) {
      setAvailableProjectsSummary(projectsSummary.filter((x) => x.countryId == currentUser?.countryId));
      setUnAvailableProjectsSummary(projectsSummary.filter((x) => x.countryId != currentUser?.countryId));
    }
  }, [projectsSummary]);

  useEffect(() => {
    thunkGetProjectsSummary();
  }, []);

  const handleNewProject = () => {
    if (designs.length == 0) {
      thunkOpenSnackBar({
        message: t("configurator_welcome.designs_not_available"),
        severity: "error"
      })
      return;
    }
    navigate("/start-configurator", {
      state: { isNewProject: true },
    });
  };

  const handleGoToSummary = () => {
    setSelectedProjectsSummary(checked);
    navigate("/projects-summary");
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fafafa"
      bodyBackGroundColor={`${ftsTheme.name == ABB ? "#FFFFFF" : "$FAFAFA"}`}
      customPageHeaderOffSection={
        <div
          className="ProfileImg"
          style={{
            width: "100%",
            backgroundImage: `url(${ProfileImg})`,
            backgroundRepeat: "no-repeat",
            position: "relative"
          }}
        ></div>
      }
    >
      <Box sx={{ position: 'absolute', top: 0 }}>
        <BackButton />

      </Box>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        pt={"5px"}
        pb={"32px"}
      >
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          xl={10}
          lg={10}
        >
          <Typography
            className="project__title"
            gutterBottom
            component="div"
          >
            {t("projects.label.projects")}
          </Typography>
          <Typography
            className="project__description"
            gutterBottom
            component="div"
            sx={{ wordBreak: "break-word", width: 590, height: 40 }}
          >
            {t("projects.label.description")}
          </Typography>
        </Grid>
        <Grid
          item
          alignItems={"flex-end"}
          display={"flex"}
        >
          <Button
            onClick={handleNewProject}
            sx={{
              width: "179px",
              height: "48px",
            }}
            variant="contained"
            endIcon={
              <ReactSVG
                src={AddProjectIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: 16px; height: 16px; fill: #009fe3; `);
                }}
              />
            }
          >
            <FitText textTransform="capitalize" fontSize={18}>{t("projects.button.new_project")}</FitText>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
        >
          <ProjectsTable
            projectsSummary={availableProjectsSummary}
            checked={checked}
            setChecked={setChecked}
            available
          />
        </Grid>
        <Grid
          container
          justifyContent={"flex-end"}
        >
          <Button
            disabled={!(checked.length > 0) || availableProjectsSummary.length == 0}
            onClick={handleGoToSummary}
            sx={{
              width: "387px",
              height: "56px",
              textTransform: "capitalize",
              fontSize: 18,
              color: "#ffffff"
            }}
            variant="contained"
            endIcon={
              <ArrowRightIcon fill="#ffffff" width={'16px'} height={'16px'} />
            }
          >
            <FitText>{t("buttons.go_to_summary")}</FitText>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          marginTop={5}
        >
          <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <AccordionSummary
              className="projects__accordion_label"
              disabled={unavailableProjectsSummary.length == 0}
              expandIcon={<ReactSVG src={ArrowDownIcon} />}
            >
              <Typography
                variant="h4"
                fontSize="20px"
                fontWeight={700}
                color="#000000"
              >
                {`${t("projects.accordion.label.projects_unavailable")} (${unavailableProjectsSummary.length})`}
              </Typography>
            </AccordionSummary>

            {unavailableProjectsSummary.length > 0 && (
              <ProjectsTable
                projectsSummary={unavailableProjectsSummary}
                checked={checked}
                setChecked={setChecked}
                available={false}
              />
            )}
          </Accordion>
        </Grid>
      </Grid>
    </ExternalLayout>
  );
};

export default Projects;

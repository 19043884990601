export const CARAT = "carat®";
export const BUSCH_AXCENT = "Busch-axcent®";
export const AXCENT = "axcent";
export const FUTURE = "future®";
export const SIDUS = "Sidus";
export const PURE_STAINLESS_STEEL = "pure stainless steel";
export const SKY_NIESSEN = "SKY";
export const FUTURE_LINEAR = "future® linear";
export const SOLO = "solo®";
export const DYNASTY = "dynasty";
export const ALBA = "Alba";
export const BUSCH_ART_LINEAR = "Busch-art linear®";
export const SAGA = "Saga";
export const BUSCH_DYNASTY = "Busch-dynasty®";
export const BUSCH_AXCENT_PUR = "Busch-axcent® pur";
export const BUSCH_BALANCE_SI = "Busch-balance® SI";







import { FC } from "react";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import "./ProductCustomisation.css";

const ProductCustomisation: FC<{}> = (props) => {
  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      pageHeaderTextTranslationKey="welcomeToBuschJaegerCustomisationCenter"
      navbarLogoLink="/"
      navbarMainTitle="Customisation Center"
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
      customPageHeader="Product Customisation"
    >
      <div className="productCustomisation"></div>
    </ExternalLayout>
  );
};

export default ProductCustomisation;

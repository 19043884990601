import { ReactNode } from "react";

interface INavbarRootProps {
  children: ReactNode;
}
export function NavbarRoot({ children }: INavbarRootProps) {
  return (
    <header>
      <nav
        className="navbar"
        style={{ backgroundColor: "#fff" }}
      >
        {children}
      </nav>
    </header>
  );
}

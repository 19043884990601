import { Tooltip, useTheme } from "@mui/material";
import { IInsertColor } from "../../../interfaces/IInsertColor";
import { FTSTheme } from "../../../App";
import { useStoreState } from "../../../hooks";
import { BJE } from "../../../constants/Theme";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  selectedCoverNumber: number;
  handleChangeCoverColor: (cColor: IInsertColor) => Promise<void>;
}

const InsertColorContainer: React.FC<IProps> = ({ selectedCoverNumber, handleChangeCoverColor }) => {
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { t } = useTranslation();

  return (
    <>
      <div className="insertConfiguration__mainParameterInsertColorContainer">
        {userDesignData?.design?.insertColors
          ?.filter((x) => x.validation.isValid)
          .map((insertColor, index) => (
            <Tooltip
              key={index}
              title={t(`insertcolors.${insertColor?.code}`)}
              placement="bottom"
              arrow
            >
              <div
                onClick={() => handleChangeCoverColor(insertColor)}
                style={{
                  backgroundColor: insertColor?.hexColor,
                  border:
                    insertColor?.id ===
                    userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber].insertColor?.id
                      ? `2px solid ${ftsTheme.name == BJE ? theme.palette.primary.main : "#696969"}`
                      : "",
                }}
                className={"insertConfiguration__mainParameterInsertColorItem"}
              />
            </Tooltip>
          ))}
      </div>
    </>
  );
};

export default InsertColorContainer;

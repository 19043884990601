import { Tooltip, useTheme } from "@mui/material";
import { IFrameColor } from "../../../interfaces/IFrameColor";
import { useStoreState } from "../../../hooks";
import { FTSTheme } from "../../../App";
import { BJE } from "../../../constants/Theme";
import { useTranslation } from "react-i18next";

interface IProps {
  handleColorClick: (fColor: IFrameColor) => void;
}

const FrameColorContainer: React.FC<IProps> = ({ handleColorClick }) => {
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;
  const { t } = useTranslation();

  const { userDesignData } = useStoreState((state) => state.designs.configuration);

  return (
    <>
      <div className="frameConfiguration__mainParameterFrameColorContainer">
        {userDesignData?.design?.frameColors.map((frameColor, index) => (
          <Tooltip
            key={index}
            title={t(`framecolors.${frameColor.code}`)}
            placement="bottom"
            arrow
          >
            <div
              onClick={() => handleColorClick(frameColor)}
              className="frameConfiguration__mainParameterFrameColorItem"
              style={{
                backgroundColor: frameColor.hexColor,
                border:
                  frameColor.id === userDesignData?.userCustomDesign.frameColor!.id
                    ? `2px solid ${ftsTheme.name == BJE ? theme.palette.primary.main : "#696969"}`
                    : "",
              }}
            />
          </Tooltip>
        ))}
      </div>
    </>
  );
};

export default FrameColorContainer;

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../../../components/ErrorFallback/ErrorFallback";
import { IInsertDetail } from "../../../../interfaces/IInsertDetail";
import FourButtons from "./4Tiles/FourButtons";
import OneButton from "./1Tile/OneButton";
import OneButtonOneRocker from "./2Tiles/OneButtonOneRocker";
import Rocker from "./1Tile/Rocker";
import TwoButtons from "./2Tiles/TwoButtons";
import TwoButtonsOneRocker from "./3Tiles/TwoButtonsOneRocker";
import TwoRockers from "./2Tiles/TwoRockers";
import OneSliderTwoButtons from "./3Tiles/OneSliderTwoButtons";
import OneSlider from "./1Tile/OneSlider";
import TwoSliders from "./2Tiles/TwoSliders";
import OneSliderOneRocker from "./2Tiles/OneSliderOneRocker";
import OneSliderOneButton from "./2Tiles/OneSliderOneButton";
import { EInsertType } from "../../../../enum/EInsertType";
import OneSliderTwoButtonsFlipped from "./FlippedTiles/OneSliderTwoButtonsFlipped";
import OneSliderOneButtonFlipped from "./FlippedTiles/OneSliderOneButtonFlipped";
import OneSliderOneRockerFlipped from "./FlippedTiles/OneSliderOneRockerFlipped";
import TwoSlidersFlipped from "./FlippedTiles/TwoSlidersFlipped";
interface ILayoutConfigurationProps {
  designCode: string;
  isHorizontal: boolean;
  insertType: string | undefined;
  cover: number;
  isPreview: boolean;
  canManageRef: boolean;
  insertDetail: IInsertDetail;
  setInsertDetail: (detail: IInsertDetail) => void;
}

interface ITile {
  layout: string;
  component: JSX.Element;
}

const LayoutConfiguration: React.FC<ILayoutConfigurationProps> = (props) => {
  const Tiles: ITile[] = [
    {
      layout: EInsertType.Button,
      component: (
        <ErrorBoundary
          key={"one-button"}
          FallbackComponent={ErrorFallback}
        >
          <OneButton
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.Rocker,
      component: (
        <ErrorBoundary
          key={"rocker"}
          FallbackComponent={ErrorFallback}
        >
          <Rocker
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.TwoButtons,
      component: (
        <ErrorBoundary
          key={"two-buttons"}
          FallbackComponent={ErrorFallback}
        >
          <TwoButtons
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.TwoRockers,
      component: (
        <ErrorBoundary
          key={"two-rockers"}
          FallbackComponent={ErrorFallback}
        >
          <TwoRockers
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.OneButtonOneRocker,
      component: (
        <ErrorBoundary
          key={"one-button-one-rocker"}
          FallbackComponent={ErrorFallback}
        >
          <OneButtonOneRocker
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.TwoButtonsOneRocker,
      component: (
        <ErrorBoundary
          key={"two-buttons-one-rocker"}
          FallbackComponent={ErrorFallback}
        >
          <TwoButtonsOneRocker
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.Slider,
      component: (
        <ErrorBoundary
          key={"one-slider"}
          FallbackComponent={ErrorFallback}
        >
          <OneSlider
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.TwoSliders,
      component: (
        <ErrorBoundary
          key={"two-sliders"}
          FallbackComponent={ErrorFallback}
        >
          {(props.insertDetail.isFlipped && (
            <TwoSlidersFlipped
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )) || (
            <TwoSliders
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )}
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.OneSliderOneRocker,
      component: (
        <ErrorBoundary
          key={"one-slider-one-rocker"}
          FallbackComponent={ErrorFallback}
        >
          {(props.insertDetail.isFlipped && (
            <OneSliderOneRockerFlipped
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )) || (
            <OneSliderOneRocker
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )}
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.OneSliderOneButton,
      component: (
        <ErrorBoundary
          key={"one-slider-one-button"}
          FallbackComponent={ErrorFallback}
        >
          {(props.insertDetail.isFlipped && (
            <OneSliderOneButtonFlipped
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )) || (
            <OneSliderOneButton
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )}
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.OneSliderTwoButtons,
      component: (
        <ErrorBoundary
          key={"one-slider-two-buttons"}
          FallbackComponent={ErrorFallback}
        >
          {(props.insertDetail.isFlipped && (
            <OneSliderTwoButtonsFlipped
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )) || (
            <OneSliderTwoButtons
              isHorizontal={props.isHorizontal}
              designCode={props.designCode}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
              cover={props.cover}
              isPreview={props.isPreview}
              canManageRef={props.canManageRef}
            />
          )}
        </ErrorBoundary>
      ),
    },
    {
      layout: EInsertType.FourButtons,
      component: (
        <ErrorBoundary
          key={"four-buttons"}
          FallbackComponent={ErrorFallback}
        >
          <FourButtons
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <>
      {(props.insertType === undefined && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <OneButton
            isHorizontal={props.isHorizontal}
            designCode={props.designCode}
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            cover={props.cover}
            isPreview={props.isPreview}
            canManageRef={props.canManageRef}
          />
        </ErrorBoundary>
      )) ||
        Tiles.map((tile: ITile) => {
          if (tile.layout.toLowerCase().trim() === props.insertType?.toLowerCase().trim()) {
            return tile.component;
          }
          // return;
        })}
    </>
  );
};

export default LayoutConfiguration;

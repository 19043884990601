import { Dialog, DialogContent, IconButton, Typography, useTheme } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IDialogHandle } from "../../../interfaces/IDialogHandle";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import Cover from "../Components/Cover/Cover";
import Frame from "../Components/Frame/Frame";
import CloseIcon from "../Icons/close.svg";
import LayoutConfiguration from "../InsertConfiguration/Tiles/LayoutConfiguration";
import "./DesignPreviewDialog.css";
import DesignPreviewTabs from "./DesignPreviewTabs";
import { FTSTheme } from "../../../App";

export const designPreviewDialogRef = createRef<IDialogHandle>();

interface IDesignPreviewDialogInputProps {
  displayPreviewTabs?: boolean;
  productDesignId?: string;
  handleCloseComposition?: () => void;
}

const DesignPreviewDialog: ForwardRefRenderFunction<IDialogHandle, IDesignPreviewDialogInputProps> = (props, ref) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { setProductDesignPreview } = useStoreActions((actions) => actions.designs);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setProductDesignPreview(null);
    setOpen(false);

    if (props.handleCloseComposition) props.handleCloseComposition();
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "780px",
            },
          },
        }}
        fullWidth={true}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            designPreviewDialogRef.current?.close();
          }}
          color="primary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 35,
            height: 35,
          }}
        >
          <ReactSVG
            onClick={() => {
              designPreviewDialogRef.current?.open();
            }}
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top: 5px`
              );
            }}
          />
        </IconButton>
        <DialogContent>
          <Typography
            variant="h2"
            fontSize="24px"
            fontWeight={700}
            mb={2}
          >
            {userDesignData?.numberOfSwitches === 1
              ? t("design_preview_dialog_preview_title")
              : t("design_preview_dialog.set_preview_title")}
          </Typography>

          {userDesignData == null && <p>{t("DesignSummaryDialog_NoContent")}</p>}

          {userDesignData != null && (
            <div className="designPreviewContent">
              <div
                className={
                  userDesignData.userCustomDesign.isHorizontal
                    ? `designPreviewBackground designPreview__mainPreview--horizontal-dialog`
                    : `designPreviewBackground designPreview__mainPreview--vertical-dialog`
                }
              >
                <Frame
                  numberOfSwitches={userDesignData.userCustomDesign?.insertDetails.length!}
                  isHorizontal={userDesignData.userCustomDesign?.isHorizontal!}
                  design={userDesignData?.design!}
                  frameColor={userDesignData?.userCustomDesign.frameColor!}
                  enableSelection={false}
                  enableTransition={false}
                >
                  {userDesignData.userCustomDesign?.insertDetails.map((detail, coverIndex) => (
                    <Cover
                      key={`cover-${coverIndex}`}
                      cover={coverIndex}
                      insertDetail={detail}
                      designCode={userDesignData.design?.code!}
                      isHorizontal={userDesignData.userCustomDesign.isHorizontal!}
                    >
                      <LayoutConfiguration
                        designCode={userDesignData.design?.code!}
                        isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                        key={`layout-configuration-preview-${coverIndex}`}
                        insertDetail={detail}
                        setInsertDetail={(detail: IInsertDetail) => {}} //change property optional
                        cover={coverIndex}
                        insertType={detail.insertType?.name}
                        isPreview={true}
                        canManageRef={false}
                      />
                    </Cover>
                  ))}
                </Frame>
              </div>
            </div>
          )}
          {props.displayPreviewTabs && <DesignPreviewTabs />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(DesignPreviewDialog);

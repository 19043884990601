import { ButtonProps, CircularProgress, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import LoadingButton from "@mui/lab/LoadingButton";
import FitText from "../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  handleGoToSummary: () => Promise<void>;
}

const AbbAddProject: React.FC<IButtonProps> = ({ loading, handleGoToSummary }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      sx={{ width: 388, height: 44, fontSize: 16, fontWeight: 500 }}
      variant="contained"
      onClick={handleGoToSummary}
    >
      <FitText>{t("design_preview.button.add_to_project")}</FitText>
    </LoadingButton>
  );
};

const BjeAddProject: React.FC<IButtonProps> = ({ loading, handleGoToSummary }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      loading={loading}
      loadingIndicator={
        <CircularProgress
          color="info"
          size={20}
        />
      }
      sx={{ width: 388, height: 56, fontSize: 18, fontWeight: 700 }}
      variant="contained"
      onClick={handleGoToSummary}
    >
      <FitText>{t("design_preview.button.add_to_project")}</FitText>
    </LoadingButton>
  );
};

const AddProjectButton: React.FC<IButtonProps> = ({ loading, handleGoToSummary }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbAddProject
          loading={loading}
          handleGoToSummary={handleGoToSummary}
        />
      )) ||
        (theme.name == BJE && (
          <BjeAddProject
            loading={loading}
            handleGoToSummary={handleGoToSummary}
          />
        ))}
    </>
  );
};

export default AddProjectButton;

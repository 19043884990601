import { ReactNode } from "react";
import Footer from "../Footer/Footer";

interface IMainLayoutRootProps {
  bodyBackgroundColor?: string;
  children: ReactNode;
}

export function MainLayoutRoot({ children, bodyBackgroundColor = "#fff" }: IMainLayoutRootProps) {
  return (
    <>
      <main
        style={{
          backgroundColor: bodyBackgroundColor,
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </main>
      <Footer showCopyrightInfo={true} />
    </>
  );
}

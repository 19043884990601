import { COUNTRIES_CODES } from "../constants/Languages"
import { ABB } from "../constants/Theme"
import { useStoreState } from "../hooks"

const useAppTitle = () => {
    const currentUser = useStoreState((state) => state.auth.currentUser)
    if (process.env.REACT_APP_THEME === ABB && currentUser?.countryId === COUNTRIES_CODES.Switzerland) { return `customization_center.switzerland` }
    else { return 'navbar_main_title' }
}

export default useAppTitle
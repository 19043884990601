import axiosInstance from "../config/axios";
import { IPrivacyNotice } from "../interfaces/IPrivacyNotice";

const PrivacyNoticeService = {
  get: (languageId: number) => {
    return axiosInstance.get<IPrivacyNotice>(`privacynotice/language/${languageId}`);
  },
};

export default PrivacyNoticeService;

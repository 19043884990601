import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { FTSTheme } from "../../App";
import ThreeDotsMenu from "../../components/ThreeDotsMenu/ThreeDotsMenu";
import { EProjectStatusDescription } from "../../enum/EProjectStatus";
import { useStoreActions } from "../../hooks";
import { useBackdrop } from "../../hooks/backdrop";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import { IProductDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import { Status } from "../../models/status";
import OpenIcon from "../Configurator/Icons/OpenIcon.svg";
import "./Projects.css";
import FitText from "../../components/FitText/FitText";

interface IProps {
  projectsSummary: IProjectSummaryDto[];
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  available: boolean;
}

interface IHeaderCell {
  id: string;
  label: string;
  width: string;
  sortable: boolean;
}

const ProjectsTable: React.FC<IProps> = ({ projectsSummary, available, setChecked, checked }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate = useNavigate();
  const { openBackdrop, closeBackdrop } = useBackdrop();

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    thunkAddCoverSet,
    setCurrentDesignId,
    setCurrentProjectId,
    setUserDesignDataFromProductDesignResponse,
    thunkGetProductDesignDataByProjectId,
  } = useStoreActions((actions) => actions.designs);

  const handleOpenProject = async (projectId: string) => {
    openBackdrop({ title: t("dashboard.div_loading").toUpperCase() });
    var response = await thunkGetProductDesignDataByProjectId(projectId);

    if (response.status == Status.FAILED) {
      thunkOpenSnackBar({
        message: t("recent_projects.message_error.get_product_design_by_userId"),
        severity: "error",
      });
      closeBackdrop();
      return;
    }

    setCurrentProjectId(projectId);

    const productDesignsFromProject = response.data as IProductDesignResponse[];

    const hasMoreThanOne = productDesignsFromProject.length > 1;
    const empty = productDesignsFromProject.length === 0;

    if (empty) {
      const result = await thunkAddCoverSet();
      if (result === Status.COMPLETED) {
        navigate("/start-configurator", { state: { isNewProject: false } });
        closeBackdrop();
        return;
      }

      thunkOpenSnackBar({
        message: t("recent_projects.message_error.get_product_design_by_userId"),
        severity: "error",
      });
      closeBackdrop();
      return;
    }

    const prodDesign = productDesignsFromProject.find((p) => p.project.id === projectId);

    setCurrentDesignId(prodDesign?.id ?? null);
    setUserDesignDataFromProductDesignResponse(prodDesign!);

    if (hasMoreThanOne) {
      navigate("/design-summary");
      closeBackdrop();
      return;
    }

    closeBackdrop();
    navigate(prodDesign?.pathname ?? "/", {
      state: {
        isNewProject: false,
        isDesignSelected: prodDesign !== undefined,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headerCells: readonly IHeaderCell[] = [
    {
      id: "name",
      label: "projects.table.header.name",
      width: "",
      sortable: true,
    },
    {
      id: "numberOfProducts",
      label: "projects.table.header.number_of_products",
      width: "",
      sortable: false,
    },
    {
      id: "createdAt",
      label: "projects.table.header.create_date",
      width: "",
      sortable: true,
    },
    {
      id: "lastModified",
      label: "projects.table.header.last_modified",
      width: "",
      sortable: true,
    },
    {
      id: "status",
      label: "projects.table.header.status",
      width: "",
      sortable: true,
    },
    {
      id: "empty",
      label: "",
      width: "",
      sortable: false,
    },
  ];

  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IProjectSummaryDto>("createdAt");

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (a: { [key in Key]: number | string | any }, b: { [key in Key]: number | string | any }) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProjectSummaryDto) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTable-root": {
            border: "1px solid #DADADA",
            borderRadius: 4,
          },
        }}
      >
        <Table className="designSummary__table">
          <TableHead className={`projects__${ftsTheme.name}_table_head`}>
            <TableRow>

              <TableCell className="projects__table_row_cell_checkbox">
                {available && (
                  <>
                    <Checkbox
                      sx={{
                        color: '#333333', '&.Mui-checked': {
                          color: '#333333'
                        },
                      }}
                      onChange={(event) => {
                        if (event.target.checked) {
                          const idArray = projectsSummary.map((item) => {
                            return item.id;
                          });
                          setChecked(idArray);
                          return;
                        }
                        setChecked([]);
                      }}
                    />
                  </>
                ) || <div className="projects__table_cell_spacing"></div>}
              </TableCell>

              {headerCells.map((headerCell: IHeaderCell) => {
                return (
                  <TableCell
                    className={`projects__table_header_cell ${headerCell.id == "name" ? "name" : ""}`}
                    width={headerCell.width}
                    key={headerCell.id}
                  >
                    {headerCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headerCell.id}
                        direction={orderBy === headerCell.id ? order : "asc"}
                        onClick={(evt) => handleRequestSort(evt, headerCell.id as keyof IProjectSummaryDto)}
                      >
                        {t(headerCell.label)}
                      </TableSortLabel>
                    ) : (
                      <>{t(headerCell.label)}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsSummary.length == 0 && (
              <>   <TableRow>
                <TableCell colSpan={4}>
                  <div className="recentProjects__listNotification">
                    <Typography
                      sx={{ marginTop: "15px" }}
                    >
                      {t("projects_youHaveNotCreatedOneYet")}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
              </>
            )}
            {projectsSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project) => {
              return (
                <TableRow key={project.id}>

                  <TableCell className="projects__table_row_cell_checkbox">
                    {available && (
                      <Checkbox
                        sx={{
                          color: '#333333', '&.Mui-checked': {
                            color: '#333333'
                          },
                        }}
                        checked={checked.includes(project.id)}
                        onChange={() => {
                          if (checked.includes(project.id)) {
                            setChecked((prev) => {
                              let array = prev.filter((item) => item !== project.id);
                              return array;
                            });
                            return;
                          }
                          setChecked((prev) => {
                            return [...prev, project.id];
                          });
                        }}
                      />
                    ) || (<></>)}

                  </TableCell>

                  <TableCell className="projects__table_row_cell name">{project.name}</TableCell>
                  <TableCell className="projects__table_row_cell">{project.productDesignsQuantity}</TableCell>
                  <TableCell className="projects__table_row_cell">
                    {moment(project.createdAt).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell className="projects__table_row_cell">
                    {moment(project.lastModified).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell className="projects__table_row_cell">
                    {t(EProjectStatusDescription.get(project?.status)!)}
                  </TableCell>
                  <TableCell>
                    {available && (
                      <>
                        <Button
                          onClick={() => handleOpenProject(project.id)}
                          sx={{
                            width: 124,
                            height: 40,
                            borderColor: "#DBDBDB",
                          }}
                          startIcon={
                            <ReactSVG
                              src={OpenIcon}
                              beforeInjection={(svg) => {
                                svg.setAttribute("style", `width: 16px; height: 16px; fill: ${ftsTheme.icon.color}; `);
                              }}
                            />
                          }
                          variant="outlined"
                          color="primary"
                        >
                          <FitText sx={{ fontSize: 14, fontWeight: 500, color: ftsTheme.icon.color }}>
                            {t("open")}
                          </FitText>
                        </Button>
                        <Box sx={{ paddingLeft: "24px", display: "initial" }}>
                          <ThreeDotsMenu data={project} enableNameEdit={true} />
                        </Box>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {projectsSummary.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          component="div"
          count={projectsSummary.length}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
};

export default ProjectsTable;

import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  IconButton,
  DialogContent,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createRef, forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { ReactSVG } from "react-svg";
import { IDialogHandle } from "../../../../interfaces/IDialogHandle";
import CloseIcon from "../../Icons/close.svg";
import "./download-project-dialog.css";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../../App";
import { useBackdrop } from "../../../../hooks/backdrop";
import { useStoreActions, useStoreState } from "../../../../hooks";
import FitText from "../../../../components/FitText/FitText";

export const downloadProjectDialogRef = createRef<IDialogHandle>();
interface IDownloadProps {
  handleExportProjectPDF: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleExportProjectXLSX: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DownloadProjectDialog: ForwardRefRenderFunction<IDialogHandle, IDownloadProps> = (props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { isExportingXLSX, isExportingPDF } = useStoreState((state) => state.app);
  const { setIsExportingXLSX, setIsExportingPDF } = useStoreActions((state) => state.app);

  const [open, setOpen] = useState(false);

  const [downloadOption, setDownloadOption] = useState({
    pdf: false,
    xlsx: false,
  });

  useEffect(() => {
    if (isExportingPDF || isExportingXLSX) {
      openBackdrop({ message: t("design_summary.download_project.backdrop_message") });
    }

    if (!isExportingPDF && !isExportingXLSX) {
      closeBackdrop();
    }
  }, [isExportingPDF, isExportingXLSX]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadOption({
      ...downloadOption,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  const handleDownloadProject = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (pdf) {
      setIsExportingPDF(true);
      props.handleExportProjectPDF(evt);
    }

    if (xlsx) {
      setIsExportingXLSX(true);
      props.handleExportProjectXLSX(evt);
    }
  };

  const { pdf, xlsx } = downloadOption;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          downloadProjectDialogRef.current?.close();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          width: 35,
          height: 35,
        }}
      >
        <ReactSVG
          onClick={() => {
            downloadProjectDialogRef.current?.open();
          }}
          src={CloseIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 24px; height: 24px; fill: ${theme.icon.color}; padding: 0px; margin-top:5px`
            );
          }}
        />
      </IconButton>
      <DialogContent className="projectdesign-preview-download-project-dialog-content">
        <Typography className="projectdesign-preview-download-project-dialog-title">
          {t("DesignSummary_DowloadProject_Title")}
        </Typography>

        <FormGroup sx={{ marginTop: "15px" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="pdf"
                checked={pdf}
                onChange={handleChange}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="PDF"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="xlsx"
                checked={xlsx}
                onChange={handleChange}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="XLSX"
          />
        </FormGroup>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            sx={{
              width: "131px",
            }}
            onClick={() => {
              downloadProjectDialogRef.current?.close();
            }}
            className="projectdesign-preview-download-project-dialog-cancel"
          >
            <FitText>{t("Cancel")}</FitText>
          </Button>

          <LoadingButton
            sx={{
              width: "131px",
            }}
            disabled={!xlsx && !pdf}
            onClick={(evt) => handleDownloadProject(evt)}
            className="projectdesign-preview-download-project-dialog-download"
            variant="contained"
          >
            <FitText>{t("DesignSummary_DownloadProject_Download")}</FitText>
          </LoadingButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(DownloadProjectDialog);

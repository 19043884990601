import { Grid } from "@mui/material";
import { createRef, useEffect, useRef } from "react";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import GridLine from "../../../Components/GridLine/GridLine";
import { isHorizontal, LimitChar } from "../limitChar";
import StandardTile from "../StandardTile";
import TileConfiguration from "../TileConfiguration";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";

const OneButtonOneRocker: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileControlDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.XLARGE,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false
  };

  const tileFunctionDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_FUNCTION,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.LARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 42,
    isFunction: true,
    spaceBetweenIconAndTextArea: 4,
  };

  const tileRockerDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.LARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.SMALL,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 42,
    isFunction: false,
    spaceBetweenIconAndTextArea: 4,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={coverRef} data-designcode={props.designCode}>
        <StandardTile designCode={props.designCode}>
          <Grid
            className={`${border} tile-border-rocker-three one-button-one-rocker-margin-right one-button-one-rocker-margin-bottom printable-area-one-button-one-rocker-function`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={4}
          >
            <span style={{ display: props.isPreview ? 'block' : 'none' }}>
              <TilePreviewConfiguration
                tileDimension={tileRockerDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButtonOneRocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="1"
              />
            </span>
            <span style={{ display: !props.isPreview ? 'block' : 'none' }}>
              <TileConfiguration
                isRocker={true}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButtonOneRocker.rocker!
                    : textFieldProps.vertical.oneButtonOneRocker.rocker!
                }
                tileName="oneButtonOneRocker"
                isHorizontal={props.isHorizontal}
                designCode={props.designCode}
                tileDimension={tileRockerDimension}
                insertDetail={props.insertDetail}
                setInsertDetail={props.setInsertDetail}
                cover={props.cover}
                indexDetail="1"
                coverRef={coverRef}
                canManageRef={props.canManageRef}
              />
            </span>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker-three one-button-one-rocker-margin-left one-button-one-rocker-margin-right one-button-one-rocker-margin-bottom printable-area-one-button-one-rocker-function`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={4}
          >
            <span style={{ display: props.isPreview ? 'block' : 'none' }}>
              <TilePreviewConfiguration
                tileDimension={tileFunctionDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButtonOneRocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="2"
              />
            </span>
            <span style={{ display: !props.isPreview ? 'block' : 'none' }}>
              <TileConfiguration
                isRocker={true}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButtonOneRocker.rocker!
                    : textFieldProps.vertical.oneButtonOneRocker.rocker!
                }
                tileName="oneButtonOneRocker"
                isHorizontal={props.isHorizontal}
                designCode={props.designCode}
                tileDimension={tileFunctionDimension}
                insertDetail={props.insertDetail}
                setInsertDetail={props.setInsertDetail}
                cover={props.cover}
                indexDetail="2"
                coverRef={coverRef}
                canManageRef={props.canManageRef}
              />
            </span>
          </Grid>

          <Grid
            className={`${border} tile-border-rocker-three one-button-one-rocker-margin-bottom one-button-one-rocker-margin-left printable-area-one-button-one-rocker-function`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={4}
          >
            <span style={{ display: props.isPreview ? 'block' : 'none' }}>
              <TilePreviewConfiguration
                tileDimension={tileRockerDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButtonOneRocker"
                isRocker={true}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="3"
              />
            </span>
            <span style={{ display: !props.isPreview ? 'block' : 'none' }}>
              <TileConfiguration
                isRocker={true}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButtonOneRocker.rocker!
                    : textFieldProps.vertical.oneButtonOneRocker.rocker!
                }
                tileName="oneButtonOneRocker"
                isHorizontal={props.isHorizontal}
                designCode={props.designCode}
                tileDimension={tileRockerDimension}
                insertDetail={props.insertDetail}
                setInsertDetail={props.setInsertDetail}
                cover={props.cover}
                indexDetail="3"
                coverRef={coverRef}
                canManageRef={props.canManageRef}
              />
            </span>
          </Grid>

          <GridLine
            coverRef={coverRef}
            styleProps={{
              height: "1px",
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: 'absolute',
              width: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
              top: 'calc(50% - 0.5px)'
            }}
            enable={props.insertDetail.hasGridLine}
            cover={props.cover}
            indexDetail="5"
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[90, 270]}
            defaultOrientation="horizontal"
          />

          <Grid
            className={`${border} one-button-one-rocker-margin-top printable-area-one-button-one-rocker-control`}
            container
            item
            alignItems="center"
            justifyContent="center"
            md={12}
          >
            <span style={{ display: props.isPreview ? 'block' : 'none' }}>
              <TilePreviewConfiguration
                tileDimension={tileControlDimension}
                designCode={props.designCode}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButton
                    : textFieldProps.vertical.oneButton
                }
                tileName="oneButtonOneRocker"
                isRocker={false}
                cover={props.cover}
                insertDetail={props.insertDetail!}
                indexDetail="4"
              />
            </span>
            <span style={{ display: !props.isPreview ? 'block' : 'none' }}>
              <TileConfiguration
                isRocker={false}
                textLabelSizes={
                  isHorizontal(props.insertDetail.rotate!)
                    ? textFieldProps.horizontal.oneButtonOneRocker
                    : textFieldProps.vertical.oneButtonOneRocker
                }
                tileName="oneButtonOneRocker"
                isHorizontal={props.isHorizontal}
                designCode={props.designCode}
                tileDimension={tileControlDimension}
                insertDetail={props.insertDetail}
                setInsertDetail={props.setInsertDetail}
                cover={props.cover}
                indexDetail="4"
                coverRef={coverRef}
                canManageRef={props.canManageRef}
              />
            </span>
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default OneButtonOneRocker;

import { Button, ButtonProps, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../../App";
import { ABB, BJE } from "../../../../../constants/Theme";
import FitText from "../../../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  handleClose: () => void;
}

const AbbClose: React.FC<IButtonProps> = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 96, height: 40 }}
      variant="text"
      onClick={handleClose}
    >
      <FitText sx={{ fontSize: "14px", fontWeight: 500 }}>
        {t("select_existing_project_dialog.button.close")}
      </FitText>
    </Button>
  );
};

const BjeClose: React.FC<IButtonProps> = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 193, height: 41 }}
      onClick={handleClose}
      variant="text"
      style={{ textTransform: "unset" }}
    >
      <FitText>{t("select_existing_project_dialog.button.close")}</FitText>
    </Button>
  );
};

const CloseButton: React.FC<IButtonProps> = ({ handleClose }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbClose handleClose={handleClose} />) ||
        (theme.name == BJE && <BjeClose handleClose={handleClose} />)}
    </>
  );
};

export default CloseButton;

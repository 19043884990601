const FrameUtils = {
  getBackgroundSize: (numberOfSwitches: number, designSize: number) => {
    if (designSize == 63 || designSize == 55 || designSize == 54) {
      switch (numberOfSwitches) {
        case 1:
          return '398px 398px';

        case 2:
          return '662px 398px';

        case 3:
          return 'calc(926px) 398px'// 'calc(996px) 428px';

        case 4:
          return '1190px 398px';

        case 5:
          return '1454px 398px';

        default:
          break;
      }
    }

    if (designSize == 59) {
      switch (numberOfSwitches) {
        case 1:
          return '398px 398px';

        case 2:
          return '624px 398px';

        case 3:
          return '848px 398px'// 'calc(996px) 428px';

        case 4:
          return '1071px 398px';

        default:
          break;
      }
    }    

    if (designSize == 70) {
      switch (numberOfSwitches) {
        case 1:
          return '465px 424.56px';

        case 2:
          return '734px 424.56px';

        case 3:
          return '1003px 424.56px'// 'calc(996px) 428px';

        case 4:
          return '1271px 424.56px';

        case 5:
          return '1543px 424.56px';

        default:
          break;
      }
    }

  }
}

export default FrameUtils;
import { Button, ButtonProps, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../App";
import { ABB, BJE } from "../../constants/Theme";
import { getAuthorizeHref } from "../../auth/config";

interface IButtonProps extends ButtonProps {}

const AbbGoToLogin: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 148, height: 44 }}
      variant="contained"
      onClick={() => window.open(getAuthorizeHref(), "_self")}
    >
      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("session_expired_button")}</Typography>
    </Button>
  );
};

const BjeGoToLogin: React.FC<IButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 193, height: 41 }}
      variant="contained"
      color="primary"
      onClick={() => window.open(getAuthorizeHref(), "_self")}
    >
      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{t("session_expired_button")}</Typography>
    </Button>
  );
};

const GoToLogginButton: React.FC<IButtonProps> = () => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbGoToLogin />) || (theme.name == BJE && <BjeGoToLogin />)}</>;
};

export default GoToLogginButton;

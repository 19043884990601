import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import DesignService from "../../services/DesignService";

const BackButton: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = useStoreState((state) => state.auth.currentUser);

  const {
    userDesignData
  } = useStoreState(state => state.designs.configuration)

  const {
    thunkOpenConfirm,
    thunkCloseConfirm
  } = useStoreActions(actions => actions.app)

  const handleBack = () => {
    if (userDesignData?.userCustomDesign.designId) {
      DesignService.getById(userDesignData?.userCustomDesign.designId!)
        .then(response => {
          const currentDesign = response.data;
          if (!currentDesign.countries.map(c => c.id).includes(currentUser?.countryId!)) {
            thunkOpenConfirm({
              title: t("profile.confirmdialog.label.title"),
              message: t("profile.confirmdialog.label.message"),
              onConfirm: {
                text: t("profile.confirmdialog.button.ok"),
                onClick: () => {
                  navigate('/dashboard');
                  thunkCloseConfirm();
                }
              },
              onDismiss: {
                text: t("profile.confirmdialog.button.cancel")
              }
            })
          }
          else {
            navigate(-1)
          }
        })
    }
    else {
      navigate(-1)
    }

  }

  return (
    <Button
      variant="text"
      onClick={handleBack}
      startIcon={<ChevronLeftOutlinedIcon sx={{ width: "28px", height: "28px" }} />}
      color="inherit"
      sx={{
        marginTop: '10px',
        paddingRight: '15px'
      }}
    >
      <span>{t("buttons.back")}</span>
    </Button>
  )
}

export default BackButton;
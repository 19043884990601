import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions, useStoreState } from "../../hooks";
import CloseIcon from "../../pages/Configurator/Icons/close.svg";

export interface IAppConfirmButtonInputProps {
  onClick?: Function;
  text?: string;
  icon?: any;
}

export interface IAppConfirmInputProps {
  title: string;
  subtitle?: string;
  message: string;
  onDismiss?: IAppConfirmButtonInputProps;
  onConfirm?: IAppConfirmButtonInputProps;
  useLoading?: boolean;
  highlightCancel?: boolean
}

const AppConfirm: React.FC<{}> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    showConfirm,
    confirmTitle,
    confirmMessage,
    confirmOnConfirm,
    confirmOnDismiss,
    confirmUseLoading,
    highlightCancel
  } = useStoreState((state) => state.app);

  const {
    thunkCloseConfirm
  } = useStoreActions((actions) => actions.app);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    thunkCloseConfirm();
  }

  return (
    <Dialog
      open={showConfirm}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "480px",
            padding: '32px 24px'
          },
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          width: 35,
          height: 35,
        }}
      >
        <ReactSVG
          onClick={handleClose}
          src={CloseIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 24px; height: 24px; fill: ${theme.palette.primary.main}; padding: 0px; margin-top: 5px`
            );
          }}
        />
      </IconButton>
      <DialogTitle sx={{
        padding: '0px'
      }} id="alert-dialog-title">
        {confirmTitle}
      </DialogTitle>
      <DialogContent sx={{
        padding: '10px 0px'
      }}>
        <DialogContentText id="alert-dialog-description">
          {confirmMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{
        padding: '0px'
      }}>
        <Button
          disabled={loading}
          sx={{ textTransform: "unset", color: loading ? '#cccccc !important' : '' }}
          variant={highlightCancel ? "contained" : "text"}
          onClick={() => {
            handleClose()
            if (confirmOnDismiss?.onClick)
              confirmOnDismiss.onClick();
          }}
          endIcon={confirmOnDismiss?.icon}
        >
          {
            confirmOnDismiss?.text &&
            confirmOnDismiss.text
            ||
            t("ConfirmDialog_CancelButton_Text")
          }
        </Button>
        <LoadingButton
          loading={loading}
          loadingIndicator={<CircularProgress size={24} sx={{ color: '#FFFFFF' }} />}
          sx={{ textTransform: "unset", backgroundColor: loading ? '#cccccc !important' : '' }}
          loadingPosition="center"
          style={{ textTransform: "unset" }}
          variant={highlightCancel ? "text" : "contained"}
          onClick={() => {
            if (confirmOnConfirm?.onClick) {

              if (confirmUseLoading) {
                setLoading(true);
                confirmOnConfirm.onClick()
                  .then(() => {
                    setLoading(false);
                  })
              }
              else {
                confirmOnConfirm.onClick();
              }
            }
          }}
          autoFocus
          color="error"
          endIcon={!loading && confirmOnConfirm?.icon}
        >
          <span>
            {
              confirmOnConfirm?.text &&
              confirmOnConfirm.text
              ||
              t("ConfirmDialog_ConfirmButton_Text")
            }
          </span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AppConfirm;
import axiosInstance from "../config/axios";
import { ABB } from "../constants/Theme";
import { ECompany } from "../enum/ECompany";
import { ITermsAndConditions } from "../interfaces/ITermsAndConditions";

const TermsAndConditionsService = {
  get: (languageId: number) => {
    const theme = process.env.REACT_APP_THEME;
    const company = theme == ABB ? ECompany.ABB : ECompany.BJE;

    return axiosInstance.get<ITermsAndConditions>(`termsandconditions/language/${languageId}/${company}`);
  },
};

export default TermsAndConditionsService;

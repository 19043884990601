import { Button, ButtonProps, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";

interface IButtonProps extends ButtonProps {
  handleGoToInsertConfiguration: () => Promise<void>;
}

const AbbNext: React.FC<IButtonProps> = ({ handleGoToInsertConfiguration }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 388, height: 44 }}
      variant="contained"
      onClick={handleGoToInsertConfiguration}
    >
      <Typography fontWeight={500}>{t("frame_configuration.button.next")}</Typography>
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
          fill="white"
        />
      </svg>
    </Button>
  );
};

const BjeNext: React.FC<IButtonProps> = ({ handleGoToInsertConfiguration }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 437, height: 56 }}
      variant="contained"
      onClick={handleGoToInsertConfiguration}
    >
      <Typography
        fontSize={"18px"}
        fontWeight={700}
      >
        {t("frame_configuration.button.next")}
      </Typography>
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
          fill="white"
        />
      </svg>
    </Button>
  );
};

const NextButton: React.FC<IButtonProps> = ({ handleGoToInsertConfiguration }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbNext handleGoToInsertConfiguration={handleGoToInsertConfiguration} />) ||
        (theme.name == BJE && <BjeNext handleGoToInsertConfiguration={handleGoToInsertConfiguration} />)}
    </>
  );
};

export default NextButton;

import { ButtonProps, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as ArrowRightIcon } from "../Icons/arrow-right.svg";
import FitText from "../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  disabled: boolean;
  handleOpenFindDistributor: () => Promise<void>;
}

const AbbFindDistributor: React.FC<IButtonProps> = ({ loading, disabled, handleOpenFindDistributor }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      sx={{
        width: 359,
        height: 44,
      }}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          size={24}
          sx={{ color: "#FFFFFF" }}
        />
      }
      onClick={() => handleOpenFindDistributor()}
      variant="contained"
      disabled={disabled}
      endIcon={<ArrowRightIcon fill="#ffffff" />}
    >
      <FitText sx={{ fontSize: 16, fontWeight: 500 }}>{t("DesignSummary_FindDistributor")}</FitText>
    </LoadingButton>
  );
};

const BjeFindDistributor: React.FC<IButtonProps> = ({ loading, disabled, handleOpenFindDistributor }) => {
  const { t } = useTranslation();

  return (
    <LoadingButton
      loading={loading}
      loadingIndicator={
        <CircularProgress
          size={24}
          sx={{ color: "#FFFFFF" }}
        />
      }
      onClick={() => handleOpenFindDistributor()}
      variant="contained"
      disabled={disabled}
      endIcon={<ArrowRightIcon fill="#ffffff" />}
    >
      <FitText>{t("DesignSummary_FindDistributor")}</FitText>
    </LoadingButton>
  );
};

const FindDistributorButton: React.FC<IButtonProps> = ({ loading, disabled, handleOpenFindDistributor }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbFindDistributor
          loading={loading}
          disabled={disabled}
          handleOpenFindDistributor={handleOpenFindDistributor}
        />
      )) ||
        (theme.name == BJE && (
          <BjeFindDistributor
            loading={loading}
            disabled={disabled}
            handleOpenFindDistributor={handleOpenFindDistributor}
          />
        ))}
    </>
  );
};

export default FindDistributorButton;

import { Grid, TableCell, useTheme } from "@mui/material";
import { SetStateAction, useState } from "react";
import { EDesignStatus } from "../../../../enum/EDesignStatus";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { executeWithDebounce } from "../../../../utils/debounce";
import { IProductDesignResponse } from "../../../../interfaces/Project/Response/ProductDesignResponse";
import { FTSTheme } from "../../../../App";
import QuantityCounterButtonGroup from "./QuantityCounterButtonGroup";

interface SetQuantityAndPriceProps {
  id: string;
  numberOfCovers: number;
  designStatus: EDesignStatus;
  quantity: number;
  price: number;
  productDesign: IProductDesignResponse;
  setCoverCost: React.Dispatch<SetStateAction<number>>;
}

const SetQuantityAndPrice: React.FC<SetQuantityAndPriceProps> = (props) => {
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;

  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const { setUserDesignData, thunkAutoSave } = useStoreActions((actions) => actions.designs);

  const [currentQuantity, setCurrentQuantity] = useState(props.quantity);

  const handleDecrement = () => {
    const sumQuantity = currentQuantity <= 1 ? 1 : currentQuantity - 1;

    setCurrentQuantity(sumQuantity);

    props.setCoverCost((prev) => prev - props.price);

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...props.productDesign,
          ...{
            id: props.id,
            projectId: props.productDesign.project.id,
            designId: props.productDesign.design?.id,
            quantity: sumQuantity,
          },
        },
      },
    };

    setUserDesignData(newValues);
    executeWithDebounce(thunkAutoSave, newValues, 300);
  };

  const handleIncrement = () => {
    const sumQuantity = currentQuantity >= 99 ? 99 : currentQuantity + 1;

    setCurrentQuantity(sumQuantity);

    props.setCoverCost((prev) => prev + props.price);

    const renewUserDesignData = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...props.productDesign,
          ...{
            id: props.id,
            projectId: props.productDesign.project.id,
            designId: props.productDesign.design?.id,
            quantity: sumQuantity,
          },
        },
      },
    };

    setUserDesignData(renewUserDesignData);
    executeWithDebounce(thunkAutoSave, renewUserDesignData, 300);
  };

  return (
    <>
      <TableCell className="projectSummaryTable_cell">
        <Grid container>
          <QuantityCounterButtonGroup
            handleDecrement={handleDecrement}
            disabledDecrement={props.designStatus == EDesignStatus.Draft || currentQuantity === 1}
            iconDecrementColor={
              props.designStatus == EDesignStatus.Draft || currentQuantity === 1 ? "#BABABA" : ftsTheme.icon.color
            }
            buttonDisabledColor={props.designStatus == EDesignStatus.Draft ? "_draft" : ""}
            currentQuantity={props.designStatus == EDesignStatus.Completed ? currentQuantity.toString() : ""}
            handleIncrement={handleIncrement}
            disabledIncrement={props.designStatus == EDesignStatus.Draft || currentQuantity >= 99}
            iconIncrementColor={
              props.designStatus == EDesignStatus.Draft || currentQuantity >= 99 ? "#BABABA" : ftsTheme.icon.color
            }
          />
        </Grid>
      </TableCell>
    </>
  );
};

export default SetQuantityAndPrice;

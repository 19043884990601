const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT || "https://my-staging.busch-jaeger.de/sso/authorize";

const scopes = ["user-read-private"];

export const getAuthorizeHref = (): string => {
  const clientId = process.env.REACT_APP_CLIENT_ID || "busch-jaeger-customizing-center-stage";
  const redirectUri = process.env.REACT_APP_REDIRECT_URI || "https://knx-ui-dev.azurewebsites.net";
  return `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
    "%20"
  )}&response_type=code`;
};

export const getSignOutHref = (): string => {
  const redirectUri = process.env.REACT_APP_REDIRECT_URI || "https://knx-ui-dev.azurewebsites.net";
  const { hostname } = new URL(redirectUri);
  const baseUrl = process.env.REACT_APP_AUTH_BASEURL;
  return `${baseUrl}/user/sso-logout?sso_logout_uri=${redirectUri}&sso_host=${hostname}`;
};

import { createStore } from "easy-peasy";
import model, { StoreModel } from "./models";

const store = createStore<StoreModel>(
  model,
  {
    name: "FTS_Customisation_Center",
    devTools: process.env.REACT_APP_DEV_TOOLS === "enabled" ? true : false,
    version: 1.0,
  }
);

export default store;

export const BUTTON = "Button";
export const SLIDER = "Slider";
export const ROCKER = "Rocker";
export const ONE_SLIDER_TWO_BUTTONS = "1 Slider | 2 Buttons";
export const ONE_SLIDER_ONE_ROCKER = "1 Slider | 1 Rocker";
export const ONE_BUTTON_ONE_ROCKER = "1 Button | 1 Rocker";
export const FOUR_BUTTONS = "4 Buttons";
export const TWO_SLIDERS = "2 Sliders";
export const ONE_SLIDER_ONE_BUTTON = "1 Slider | 1 Button";
export const TWO_BUTTONS = "2 Buttons";
export const TWO_BUTTONS_ONE_ROCKER = "2 Buttons | 1 Rocker";
export const TWO_ROCKERS = "2 Rockers";
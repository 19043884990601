import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IIcon } from "../interfaces/IIcon";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) =>
    axiosInstance.get(url, options).then(responseBody),
};

const IconService = {
  get: (): Promise<AxiosResponse<IIcon[]>> => requests.get("icon"),
};

export default IconService;

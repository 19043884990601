import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, IconButton, Typography, useTheme } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions } from "../../../../hooks";
import { IProductDesignELBridge } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import { IDialogHandle } from "../../../../interfaces/IDialogHandle";
import CloseIcon from "../../Icons/close.svg";
import InfoIcon from "../../Icons/info3.svg";
import NewWindowIcon from "../../Icons/open-new-window.svg";
import "./find-distributor-dialog.css";
import { FTSTheme } from "../../../../App";

export const findDistributorDialogRef = createRef<IDialogHandle>();

interface IFindDistributorProps {
  elBridge: IProductDesignELBridge[];
}

const FindDistributorDialog: ForwardRefRenderFunction<IDialogHandle, IFindDistributorProps> = (props, ref) => {
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { t } = useTranslation();
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  const openElBridge = () => {
    try {
      var form = document.createElement("form");

      form.target = "elBridge";
      form.method = "POST";
      form.action = process.env.REACT_APP_ELBRIDGE_URL!;

      var params = {
        version: "2.0",
        result: {
          CONFIGURATION_URL: "https://www.elbridge.de/Testkonfiguration1",
          ITEM: props.elBridge,
        },
      };

      for (var i in params) {
        if (params.hasOwnProperty(i)) {
          var input = document.createElement("input");
          input.type = "hidden";
          input.name = i;
          input.value = i === "version" ? params[i] : JSON.stringify(params[i as keyof typeof params]);
          form.appendChild(input);
        }
      }

      document.body.appendChild(form);
      form.submit();
      window.open("", "elBridge");
    } catch (err: any) {
      thunkOpenSnackBar({
        message: t("DesignSummary_ELBridgeDialog_ErrorToCallELBridge") + err.message,
        severity: "error",
      });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          findDistributorDialogRef.current?.close();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          width: 35,
          height: 35,
        }}
      >
        <ReactSVG
          onClick={() => {
            findDistributorDialogRef.current?.open();
          }}
          src={CloseIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 24px; height: 24px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top:5px`
            );
          }}
        />
      </IconButton>
      <DialogContent className="design-preview-find-distributor-dialog-content">
        <div className="design-preview-find-distributor-dialog-image" />
        <Typography className="design-preview-find-distributor-dialog-title">
          {t("DesignSummary_ELBridgeDialog_Title")}
        </Typography>
        <Typography className="design-preview-find-distributor-dialog-description">
          {t("DesignSummary_ELBridgeDialog_Description")}
        </Typography>
        <Typography className="design-preview-find-distributor-dialog-info">
          <ReactSVG
            src={InfoIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 16px; height: 16px; fill: #1B7AC2; float: left; margin: 11px 11px 11px 0px;`
              );
            }}
          />
          {t("DesignSummary_ELBridgeDialog_Info")}
        </Typography>
        <div className="design-preview-find-distributor-dialog-buttons">
          <Button
            onClick={() => {
              findDistributorDialogRef.current?.close();
            }}
            className="cancel"
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            onClick={() => {
              openElBridge();
            }}
            className="confirm"
            variant="contained"
            endIcon={
              <ReactSVG
                src={NewWindowIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: 16px; height: 16px; fill: #009EE2;`);
                }}
              />
            }
          >
            <span>{t("DesignSummary_ELBridgeDialog_ConfirmButton")}</span>
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(FindDistributorDialog);

import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";
import FitText from "../../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  handleNewProject: () => void;
}

const AbbNewProject: React.FC<IButtonProps> = ({ handleNewProject }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 157, height: 44, marginRight: "16px" }}
      variant="contained"
      onClick={handleNewProject}
    >
      <FitText sx={{ fontWeight: 500 }}>{t("newProject")}</FitText>
    </Button>
  );
};

const BjeNewProject: React.FC<IButtonProps> = ({ handleNewProject }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleNewProject}
      className="welcomePage__btn welcomePage__newProjectBtn"
      variant="contained"
    >
      <FitText>{t("newProject")}</FitText>
    </Button>
  );
};

const NewProjectButton: React.FC<IButtonProps> = ({ handleNewProject }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbNewProject handleNewProject={handleNewProject} />) ||
        (theme.name == BJE && <BjeNewProject handleNewProject={handleNewProject} />)}
    </>
  );
};

export default NewProjectButton;

import { IResult } from "../interfaces/IResult";
import { Status } from "../models/status";

export const handleError = (error?: any) => {
  const status = Status.FAILED;
  const message = (error && error.response)
  ? error.response.data
  : 'Something went wrong.'

  const result: IResult<string> = {
    status: status,
    message: message,
  }

  return result;
}
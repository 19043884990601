import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IMainLayoutPageHeaderProps {
  pageHeaderTranslationKey: string;
}

export function MainLayoutPageHeader({ pageHeaderTranslationKey }: IMainLayoutPageHeaderProps) {
  const { t } = useTranslation();
  const welcomeMessage = t(pageHeaderTranslationKey!).split(" ");

  return (
    <>
      <div className="pageHeader">
        <Typography
          variant="h6"
          fontWeight="400"
          fontSize="18px"
          mt="24px"
          className="pageHeader__span"
        >
          {welcomeMessage[0] + " " + welcomeMessage[1]}
        </Typography>
      </div>
    </>
  );
}

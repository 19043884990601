import { Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import { useStoreActions, useStoreState } from "../../../../hooks";
import TrashIcon from "../../Icons/TrashIcon.svg";
import { Status } from "../../../../models/status";
import { useTranslation } from "react-i18next";
import CoverItemsRefsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items-refs";
import CoverItemsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items";
import ExpandIcon from "@mui/icons-material/Expand";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IInsertDetail, IInsertDetailItem } from "../../../../interfaces/IInsertDetail";
import { IUserDesignData } from "../../../../interfaces/IUserDesignData";
import { useBackdrop } from "../../../../hooks/backdrop";

interface ICoverActionsMenu {
  selectedCoverNumber: number;
  setSelectedCoverNumber: Function;
  setInsertDetail: (detail: IInsertDetail) => void;
}

const CoverActionsMenu: React.FC<ICoverActionsMenu> = (props) => {
  const { openBackdrop, closeBackdrop } = useBackdrop();
  const { t } = useTranslation();

  const { thunkOpenConfirm, thunkCloseConfirm, thunkOpenSnackBar, setEnableDimensionComponent } = useStoreActions(
    (actions) => actions.app
  );

  const { enableDimensionComponent } = useStoreState((actions) => actions.app);

  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const disableDeleteButton = userDesignData?.userCustomDesign.insertDetails.length === 1;
  const disableCloneButton = userDesignData?.numberOfSwitches! === userDesignData?.userCustomDesign?.frameColor?.numberOfFolds!
  const optionClassName = userDesignData?.userCustomDesign.isHorizontal ? "insertConfiguration__option" : "insertConfiguration__option--vertical"

  const {
    setUserDesignData,
    thunkCloneCover,
    thunkDeleteCoverAzStorage,
    thunkAutoSave,
    setSelectedCoverNumberGlobal,
    setFetchingCover,
  } = useStoreActions((actions) => actions.designs);

  const { frameFolds } = useStoreState((state) => state.designs.data);

  const handleDeleteCover = () => {
    if (disableDeleteButton) return
    thunkOpenConfirm({
      title: t("InsertConfiguration_DeleteDialog_Title"),
      message: t("InsertConfiguration_DeleteDialog_Message"),
      onDismiss: {
        text: t("InsertConfiguration_DeleteDialog_CancelButton_Text"),
      },
      onConfirm: {
        text: t("InsertConfiguration_DeleteDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDeleteCover(),
        icon: (
          <ReactSVG
            src={TrashIcon}
            beforeInjection={(svg) => {
              svg.setAttribute("style", "width: 16px; height: 16px; fill: #FFFFFF !important");
            }}
          />
        ),
      },
      useLoading: true,
    });
  };

  const handleSubmitDeleteCover = async () => {
    let newSelectedCoverNumber = props.selectedCoverNumber;

    if (props.selectedCoverNumber > 0) {
      newSelectedCoverNumber = props.selectedCoverNumber - 1;
    }

    const insertDetailIdRemoved = userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber].id;
    const coverDesignIdToBeRemoved =
      userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber].coverDesignId;

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails
      .filter((detail, index) => index != props.selectedCoverNumber)
      .map((detail, index) => {
        detail.order = index + 1;
        return detail;
      });

    CoverItemsRefsFunctions.updateCoverIndexRefs(props.selectedCoverNumber);

    const newNumberOfSwitches = userDesignData?.numberOfSwitches! - 1;
    const newFrameFold = frameFolds.find((ff) => ff.frameCode == newNumberOfSwitches.toString())!;

    const newValues = {
      ...userDesignData!,
      ...{
        numberOfSwitches: newNumberOfSwitches,
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            frameFold: newFrameFold,
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);
    props.setSelectedCoverNumber(newSelectedCoverNumber);
    setSelectedCoverNumberGlobal(newSelectedCoverNumber);
    CoverItemsFunctions.RemoveByInsertDetailId(insertDetailIdRemoved!);

    const res = await thunkAutoSave(newValues);
    await thunkDeleteCoverAzStorage([coverDesignIdToBeRemoved!]);

    if (res.status == Status.COMPLETED) {
      thunkCloseConfirm();
    } else {
      thunkOpenSnackBar({
        message: t("InsertConfiguration_DeleteDialog_Error_Message"),
        severity: "error",
      });
    }
  };

  const handleCloneCover = async () => {
    if (disableCloneButton) return
    openBackdrop({ message: t("insert_configuration.button.cloning") });

    const insertDetailCloned = userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber];

    const newNumberOfSwitches: number = userDesignData?.numberOfSwitches! + 1;

    const newFrameFold = frameFolds.find((ff) => ff.frameCode == newNumberOfSwitches.toString()!);

    const newInsertDetails = JSON.parse(
      JSON.stringify(userDesignData?.userCustomDesign.insertDetails)
    ) as IInsertDetail[];

    const items = newInsertDetails![props.selectedCoverNumber].items
      ? newInsertDetails![props.selectedCoverNumber].items.map((item) => {
        delete item.id;
        delete item.insertDetailId;
        return item;
      })
      : [];

    const itemsCloned = JSON.parse(JSON.stringify(items)) as IInsertDetailItem[];

    const newInsertDetail: IInsertDetail = {
      isFlipped: insertDetailCloned?.isFlipped,
      insertType: insertDetailCloned?.insertType,
      insertColor: insertDetailCloned?.insertColor,
      order: newNumberOfSwitches,
      rotate: insertDetailCloned?.rotate,
      hasGridLine: insertDetailCloned?.hasGridLine,
      items: itemsCloned,
    };

    newInsertDetails?.push(newInsertDetail!);

    const newValues: IUserDesignData = {
      ...userDesignData!,
      ...{
        numberOfSwitches: newNumberOfSwitches,
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            frameFold: newFrameFold,
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    var response = await thunkCloneCover(newValues);

    if (response.status == Status.COMPLETED) {
      props.setSelectedCoverNumber(newNumberOfSwitches - 1);
    }

    if (response.status == Status.FAILED) {
      thunkOpenSnackBar({
        message: response.message,
        severity: "error",
      });
    }

    closeBackdrop();
  };

  return (
    <>
      <div
        onClick={handleCloneCover}
        className={`${optionClassName} ${disableCloneButton ? "insertConfiguration__optionDisabled" : ""}`}
      >
        <FileCopyIcon />

        <Typography variant="caption">  {t("InsertConfiguration_ActionButtons_More_CloneButton")}
        </Typography>
      </div>
      <div className="insertConfiguration__optionSeparator"></div>
      {process.env.REACT_APP_SENTRY_ENV != "Production" ?
        <>

          <div
            onClick={() => setEnableDimensionComponent(!enableDimensionComponent)}
            className={optionClassName}
          >
            <ExpandIcon />
            <Typography variant="caption"> {enableDimensionComponent
              ? t("cover_actions_menu.disable_dimensions")
              : t("cover_actions_menu.enable_dimensions")}</Typography>

          </div>
          <div className="insertConfiguration__optionSeparator"></div></>
        : null
      }
      <div
        onClick={handleDeleteCover}
        className={`${optionClassName} ${disableDeleteButton ? "insertConfiguration__optionDisabled" : ""}`}
      >
        <DeleteForeverIcon color="primary" />
        <Typography variant="caption">
          <div style={{ color: "#E12E2E" }}>

            {t("InsertConfiguration_ActionButtons_More_DeleteButton")}
          </div>
        </Typography>
      </div>
    </>
  );
};

export default CoverActionsMenu;

import { useEffect } from "react";
import { useStoreState } from "../../../../hooks";
import WithElBridge from "./WithElBridge";
import WithNoElBridge from "./WithNoElBridge";

interface IProps {
  refreshProductDesign: () => Promise<void>;
}

const OrderSummary: React.FC<IProps> = ({ refreshProductDesign }) => {
  const { currentUser } = useStoreState((state) => state.auth);
  return (
    <>
      {(currentUser?.isElBridgeAvailable && <WithElBridge refreshProductDesign={refreshProductDesign} />) || (
        <WithNoElBridge refreshProductDesign={refreshProductDesign} />
      )}
    </>
  );
};

export default OrderSummary;

import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { RegisterUserRequest } from "../interfaces/Register/Request/RegisterUserRequest";
import { IUserDto } from "../interfaces/DTO/IUserDto";

const UserService = {
  AcceptTermsAndConditions: (email: string) => {
    return axiosInstance.post<IUserDto>(`users/accept-terms-and-conditions?email=${email}`,)
  },
  registerUser: (payload: RegisterUserRequest): Promise<AxiosResponse<IUserDto>> => {
    return axiosInstance.post<IUserDto>("users", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  },
  updateCountryAndLanguage: (email: string, countryCode: string, languageId: number) => {
    return axiosInstance.post("users/UpdateCountryAndLanguage", { email, countryCode, languageId })
  }
};

export default UserService;

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import searchIllustration from "./search_illustration.png";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import "./NotFound.css";
import { Typography } from "@mui/material";
import useAppTitle from "../../hooks/useAppTitle";

const NotFound: FC<{}> = (props) => {
  const { t } = useTranslation();
  const appTitle = useAppTitle();

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarMainTitle={appTitle}
      navbarLogoLink="/"
    >
      <div className="notFound">
        <img
          className="notFound__image"
          src={searchIllustration}
          alt="404 - Page not found"
        />
        <h1 className="notFound__title">404 - {t("pageNotFound")}</h1>
        <Typography className="notFound__description">{t("pageNotFoundDescription")}</Typography>
        <Link to="/" className="notFound__loadBtn">
          <RefreshOutlinedIcon fontSize="medium" />
          <span>{t("goBackToMainPage")}</span>
        </Link>
      </div>
    </ExternalLayout>
  );
};

export default NotFound;

export function MainLayoutContent({ children }) {
  return (
    <>
      <section className="content-section">
        {/* {showPageHeader && <></>} */}

        {/* {customPageHeader !== undefined && (
          <div className="pageHeader">
            <Typography
              variant="h4"
              gutterBottom
              component="div"
            >
              {customPageHeader}
            </Typography>
          </div>
        )} */}
        {children}
      </section>
    </>
  );
}

import { FC, RefObject, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useStoreState } from "../../../../hooks";
import { IInsertColor } from "../../../../interfaces/IInsertColor";
import EditIcon from "../../Icons/EditIcon.svg";
import { IIcon } from "../../../../interfaces/IIcon";
interface IIconHandlerProps {
  id: string | null;
  iconDimension: string;
  renderEmpty: boolean;
  insertColor?: IInsertColor;
  isFunction: boolean;
  ref?: RefObject<any>;
  indexDetail: string;
  cover: number;
}

const IconHandler: FC<IIconHandlerProps> = ({
  id,
  iconDimension,
  renderEmpty,
  insertColor,
  isFunction,
  indexDetail,
  cover
}) => {
  const { icons } = useStoreState((state) => state.designs.data);
  const [icon, setIcon] = useState<IIcon | null>(null)

  const iconColor = isFunction
    ? insertColor?.secondaryPrintColor?.hexColor!
    : insertColor?.primaryPrintColor?.hexColor!;

  useEffect(() => {
    const icon = icons.find((icon) => icon.id === id) ?? null;
    setIcon(icon)
  }, [id])

  useEffect(() => {
    fetch(icon?.blobUrl!)
      .then(res => res.text())
      .then(res => forceCss(res))
  }, [icon])

  const forceCss = (innerHTMLinput: string) => {
    if (innerHTMLinput) {
      const st0Class = innerHTMLinput.substring(innerHTMLinput.indexOf('.st0'), innerHTMLinput.indexOf(';}') + 2)
      let st0ClassNew = st0Class.replace('.st0', `#icon-cover-${cover}-indexDetail-${indexDetail} .st0`)
      st0ClassNew = st0ClassNew.replace(';}', ` !important;} `)

      const styleExists = document.getElementById(`style-for-icon-cover-${cover}-indexDetail-${indexDetail}`)
      if (styleExists) {
        styleExists.remove()
      }

      if (st0ClassNew.trim().length) {
        var styleElement = document.createElement("style");
        styleElement.innerHTML = st0ClassNew;
        styleElement.id = `style-for-icon-cover-${cover}-indexDetail-${indexDetail}`
        document.body.appendChild(styleElement);
      }
    }
  }

  return (
    <>
      {
        <ReactSVG
          style={{
            display: 'flex'
          }}
          src={icon == null && !renderEmpty ? EditIcon : icon?.blobUrl!}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: ${iconDimension}; height: ${iconDimension}; fill: ${iconColor}; display: flex; `
            );
            svg.setAttribute(
              "id",
              `icon-cover-${cover}-indexDetail-${indexDetail}`
            );

            // svg.setAttribute(
            //   "innerHTML",
            //   elementState || ''
            // );
          }}
        />
      }
    </>
  );
};

export default IconHandler;

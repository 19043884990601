import { Action, action } from "easy-peasy";
import i18n from "../i18n";
import { ILoggedUser } from "../interfaces/DTO/ILoggedUser";
export interface IAuthModel {
  isGuest: boolean;
  languageCode: string;
  currentUser: ILoggedUser | null;
  setCurrentUser: Action<IAuthModel, ILoggedUser>;
  setLanguageCode: Action<IAuthModel, string>;
  setIsGuest: Action<IAuthModel, boolean>;
}

const auth: IAuthModel = {
  isGuest: false,
  currentUser: null,
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setIsGuest: action((state, payload) => {
    state.isGuest = payload;
  }),
  languageCode: localStorage.getItem("lang")!,
  setLanguageCode: action((state, payload) => {
    state.languageCode = payload;
    localStorage.setItem("lang", payload);
    i18n.changeLanguage(payload);
  }),
};

export default auth;

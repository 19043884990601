import { FC } from "react";
import { SpinnerDotted } from "spinners-react";

const Spinner: FC<{}> = (props) => {
  return (
    <SpinnerDotted
      size={61}
      thickness={180}
      speed={121}
      color={"#000"}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

export default Spinner;

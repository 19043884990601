import { RefObject, useEffect, useRef } from "react";
import TooltipDimension from "../../../../components/TooltipDimension/TooltipDimension";
import { IInsertDetail, EInsertDetailItemType } from "../../../../interfaces/IInsertDetail";
import CoverItemsRefsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items-refs";
import CoverItemsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items";

interface ISliderLineProps {
  coverRef: RefObject<HTMLDivElement>;
  elementRef: RefObject<any>;
  className: string;
  cover: number;
  indexDetail: string;
  insertDetail: IInsertDetail;
  setInsertDetail: (detail: IInsertDetail) => void;
}

const SliderLine: React.FC<ISliderLineProps> = (props) => {
  useEffect(() => {
    async function AddSliderLineRef() {
      await CoverItemsRefsFunctions.addOrUpdateItemRef({
        indexDetail: props.indexDetail,
        coverIndex: props.cover,
        type: EInsertDetailItemType.Slider,
        coverRef: props.coverRef,
        itemRef: props.elementRef,
      });
    }

    async function AddSliderLineItem() {
      await AddSliderLineRef();

      await CoverItemsFunctions.addOrUpdateSliderLine({
        coverIndex: props.cover,
        indexDetail: props.indexDetail,
        color: props.insertDetail?.insertColor?.primaryPrintColor?.ralColorCode!,
        insertDetail: props.insertDetail,
        setInsertDetail: props.setInsertDetail,
      });
    }

    if (props.elementRef.current != null && props.insertDetail.id) {
      AddSliderLineItem();
    }
  }, [props.elementRef, props.insertDetail.isFlipped]);

  return (
    <>
      <TooltipDimension
        coverRef={props.coverRef}
        elementRef={props.elementRef}
        enableDimensionBorder={true}
        enableDimensionLine={false}
        enableStartEndPositions={true}
      >
        <hr
          ref={props.elementRef!}
          className={props.className}
          style={{
            border: 'none',
            backgroundColor: props.insertDetail.insertColor?.primaryPrintColor?.hexColor,
          }}
        />
      </TooltipDimension>
    </>
  );
};

export default SliderLine;

import { Dialog, IconButton, DialogContent, Typography, Button } from "@mui/material";
import { createRef, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { ReactSVG } from "react-svg";
import { IDialogHandle } from "../../../interfaces/IDialogHandle";
import CloseIcon from "../Icons/close.svg";
import "./design-range-dialog.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../App";

interface IProps {
  handleConfirm: () => void;
}

export const designRangeConfirmationDialogRef = createRef<IDialogHandle>();

const DesignRangeConfirmationDialog: ForwardRefRenderFunction<IDialogHandle, IProps> = (props, ref) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            designRangeConfirmationDialogRef.current?.close();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            width: 35,
            height: 35,
          }}
        >
          <ReactSVG
            onClick={() => {
              designRangeConfirmationDialogRef.current?.open();
            }}
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 24px; height: 24px; fill:${theme.icon.color}; padding: 0px; margin-top:5px`
              );
            }}
          />
        </IconButton>
        <DialogContent className="frame-configuration-design-range-dialog-content">
          <Typography className="frame-configuration-design-range-dialog-title">
            {t("FrameConfiguration_Dialog_Title")}
          </Typography>
          <Typography className="frame-configuration-design-range-dialog-description">
            {t("FrameConfiguration_Dialog_Description")}
          </Typography>
          <div className="frame-configuration-design-range-buttons">
            <Button
              onClick={() => {
                designRangeConfirmationDialogRef.current?.close();
              }}
              className="frame-configuration-design-range-dialog-cancel"
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                props.handleConfirm();
              }}
              className="frame-configuration-design-range-dialog-confirm"
            >
              {t("Confirm")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(DesignRangeConfirmationDialog);

import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Button from "@mui/material/Button";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ExternalLayout from "../../../../components/Layout/ExternalLayout";
import { useStoreActions, useStoreState } from "../../../../hooks";
import "./ConfiguratorWelcome.css";
import { Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import OpenProjectButton from "./OpenProjectButton";
import NewProjectButton from "./NewProjectButton";
import SelectExistingProjectDialog, {
  selectExistingProjectDialogRef,
} from "../WelcomeModal/OpenProjectDialog/SelectExistingProjectDialog";
import { FTSTheme } from "../../../../App";
import useAppTitle from "../../../../hooks/useAppTitle";
import { COUNTRIES_CODES } from "../../../../constants/Languages";
import { ABB } from "../../../../constants/Theme";

const ConfiguratorWelcome: FC<{}> = (props) => {
  const theme = useTheme() as FTSTheme;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { designs } = useStoreState((state) => state.designs.data);
  const { currentUser } = useStoreState((state) => state.auth);
  const isSwitzerland = currentUser?.countryId === COUNTRIES_CODES.Switzerland
  const { setCurrentProjectId, setCurrentDesignId, setUserDesignData, setUserDesignDataFromDesign } = useStoreActions(
    (actions) => actions.designs
  );
  const headerText = theme.name === ABB && isSwitzerland ? `abb.customization_center.switzerland` : `${theme.name}.welcome.app_title`
  const { thunkOpenSnackBar } = useStoreActions(
    (actions) => actions.app
  );

  const appTitle = useAppTitle()

  useEffect(() => {
    setCurrentProjectId(null);
    setCurrentDesignId(null);
    setUserDesignData(null);
  }, []);

  const handleNewProject = () => {
    if (designs.length == 0) {
      thunkOpenSnackBar({
        message: t("configurator_welcome.designs_not_available"),
        severity: "error"
      })
      return;
    }
    setUserDesignDataFromDesign(designs[0]);
    navigate("/start-configurator", {
      state: { isNewProject: true },
    });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
    >
      <div className="welcomePage">
        <Link
          className="welcomePage__backToDashboardLink"
          to="/dashboard"
        >
          <Button variant="text">
            <ChevronLeftOutlinedIcon fontSize="large" />
            {t("configuratorGoBackToDashboard")}
          </Button>
        </Link>
        <div className="welcomePage__demo">
          <div className="welcomePage__demoImage">
            {isSwitzerland ? (
              <img
                src={`${process.env.REACT_APP_BLOB_STORAGE_URL}/images/app-images/fts-banner-swiss.png`}
                alt="demo-img"
              />) : (<img
                src={`${process.env.REACT_APP_BLOB_STORAGE_URL}/images/app-images/fts-banner.png`}
                alt="demo-img"
              />)}
            <div className="fade" />
          </div>
          <div className="welcomePage__demoOptions">
            <Typography
              variant="h5"
              className="welcomePage__demoTitle"
            >
              <span style={{ fontWeight: 500 }}>{t("welcomeTo")}</span>
              <br />
              {t(headerText)}
            </Typography>
            <Typography
              variant="body2"
              m="12px 0 31px 0"
            >
              {t("pleaseChooseIfYouWantToCreate")}
            </Typography>
            <div className="welcomePage__demoOptionsBtn">
              <NewProjectButton handleNewProject={handleNewProject} />
              <OpenProjectButton />
            </div>
          </div>
        </div>
      </div>
      <SelectExistingProjectDialog ref={selectExistingProjectDialogRef} />
    </ExternalLayout>
  );
};

export default ConfiguratorWelcome;

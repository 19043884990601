import NavLink from "./NavLink";

interface INavbarLinkProps {
  translationKey: string;
  path: string;
}

export function NavbarLink({ translationKey, path }: INavbarLinkProps) {
  return (
    <NavLink
      translationKey={translationKey}
      path={path}
    />
  );
}

import { useState } from "react";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Button, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditIcon from "../../Icons/EditIcon.svg";
import TrashIcon from "../../Icons/TrashIcon.svg";
import { EDesignStatus } from "../../../../enum/EDesignStatus";
import { Status } from "../../../../models/status";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../../App";

interface IActionsProps {
  productDesignId: string;
  designStatus: EDesignStatus;
}

const Actions: React.FC<IActionsProps> = (props) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const ftsTheme = useTheme() as FTSTheme;

  const {
    setUserDesignDataFromProductDesignResponse,
    thunkDeleteProductDesign,
    thunkSetCurrentStatus,
    thunkSetCurrentPathName,
    setIsModifying,
  } = useStoreActions((actions) => actions.designs);

  const { thunkOpenSnackBar, thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);

  const { productDesigns } = useStoreState((state) => state.designs.data);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleModify = async (productDesignId: string) => {
    const productDesign = productDesigns.find((x) => x.id == productDesignId)!;

    setUserDesignDataFromProductDesignResponse(productDesign);

    await thunkSetCurrentStatus(EDesignStatus.Draft);
    await thunkSetCurrentPathName(productDesign.design === null ? "/start-configurator" : "/frame-configuration");

    setIsModifying(true);

    navigate(productDesign.design === null ? "/start-configurator" : "/frame-configuration", {
      state: { selectedCoverNumber: 0, isDesignSelected: true },
    });
  };

  const handleDeleteProductDesign = (productDesignId: string) => {
    thunkOpenConfirm({
      title: t("DesignSummaryDialog_Delete_Title"),
      message: t("DesignSummaryDialog_Delete_Message"),
      useLoading: true,
      onDismiss: {
        text: t("DesignSummaryDialog_CancelButton_Text"),
      },
      onConfirm: {
        text: t("DesignSummaryDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDeleteProductDesign(productDesignId),
        icon: (
          <ReactSVG
            src={TrashIcon}
            beforeInjection={(svg) => {
              svg.setAttribute("style", "fill: #FFFFFF !important");
            }}
          />
        ),
      },
      highlightCancel: true
    });
  };

  const handleSubmitDeleteProductDesign = async (productDesignId: string) => {
    const result = await thunkDeleteProductDesign(productDesignId);

    if (result === Status.FAILED) {
      thunkOpenSnackBar({
        message: t("DesignSummaryDialog_DeleteError_Message"),
        severity: `error`,
      });
      thunkCloseConfirm();
      return;
    }

    thunkOpenSnackBar({
      message: t("DesignSummaryDialog_DeleteSuccess_Message"),
      severity: `success`,
    });

    thunkCloseConfirm();
  };

  return (
    <>
      <IconButton
        style={{ color: ftsTheme.icon.color }}
        onClick={handleOpenOptions}
      >
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseOptions}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.designStatus === EDesignStatus.Completed && (
          <MenuItem onClick={handleCloseOptions}>
            <Button
              onClick={() => handleModify(props.productDesignId)}
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                color: "#000000 !important",
                textTransform: "capitalize",
              }}
              startIcon={
                <ReactSVG
                  src={EditIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 16px; height: 16px;");
                  }}
                />
              }
            >
              {t("DesignSummary_Modify")}
            </Button>
          </MenuItem>
        )}
        <MenuItem onClick={handleCloseOptions}>
          <Button
            onClick={() => handleDeleteProductDesign(props.productDesignId)}
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              color: "#000000 !important",
            }}
            startIcon={
              <ReactSVG
                src={TrashIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 16px; height: 16px; fill: black");
                }}
              />
            }
          >
            <Typography>{t("DesignSummary_Delete")}</Typography>
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Actions;

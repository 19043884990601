import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IIconCategory } from "../interfaces/IIconCategory";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) =>
    axiosInstance.get(url, options).then(responseBody),
};

const IconCategoryService = {
  get: (): Promise<AxiosResponse<IIconCategory[]>> => requests.get("category"),
};

export default IconCategoryService;

import { RefObject, useEffect, useRef, useState } from "react";
import "./grid-line.css";
import TooltipDimension from "../../../../components/TooltipDimension/TooltipDimension";
import { IElementSize } from "../../../../interfaces/DTO/IElementSize";
import CoverItemsRefsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items-refs";
import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import CoverItemsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items";

export interface IStyleProps {
  width?: string;
  height?: string;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
  left?: string;
  right?: string;
  top?: string;
  backgroundColor?: string;
}
interface IProps {
  styleProps: IStyleProps;
  enable?: boolean;
  coverRef: RefObject<HTMLDivElement>;
  cover: number;
  indexDetail: string;
  insertDetail: IInsertDetail;
  setInsertDetail: (detail: IInsertDetail) => void;
  verticalDegrees: number[];
  defaultOrientation: "vertical" | "horizontal";
}

const GridLine: React.FC<IProps> = (props) => {
  const separationLineRef = useRef<HTMLDivElement>(null);

  const getSeparationLineColor = () => {
    const { insertDetail, styleProps } = props;
    const primaryColor = insertDetail?.insertColor?.primaryPrintColor;
    const secondaryColor = insertDetail?.insertColor?.secondaryPrintColor;
    const colorFromStyles = styleProps?.backgroundColor;

    if (colorFromStyles === primaryColor?.hexColor!) {
      return primaryColor?.ralColorCode!;
    } else return secondaryColor?.ralColorCode!;
  }

  useEffect(() => {
    async function AddSeparationLineRef() {
      await CoverItemsRefsFunctions.addOrUpdateItemRef({
        indexDetail: props.indexDetail,
        coverIndex: props.cover,
        type: EInsertDetailItemType.SeparationLine,
        coverRef: props.coverRef!,
        itemRef: separationLineRef,
      });

      await CoverItemsFunctions.addOrUpdateSeparationLine({
        coverIndex: props.cover,
        indexDetail: props.indexDetail,
        color: getSeparationLineColor(),
        insertDetail: props.insertDetail,
        setInsertDetail: props.setInsertDetail,
      });
    }

    if (separationLineRef.current != null) {
      AddSeparationLineRef();
    }
  }, [separationLineRef, props.insertDetail.rotate, props.insertDetail.isFlipped, props.insertDetail.hasGridLine]);

  const getSliderSize = () => {
    const elementSize: IElementSize = {
      width: separationLineRef.current?.offsetWidth!,
      height: separationLineRef.current?.offsetHeight!,
    };

    return elementSize;
  };

  const getOrientation = () => {
    if (props.verticalDegrees.includes(props.insertDetail.rotate!)) {
      return "vertical";
    }

    return "horizontal";
  };

  const getRotate = () => {
    const rotate = props.insertDetail.rotate ?? 0;

    if (rotate == 90) {
      return 180;
    }
    if (rotate == 180) {
      return 180;
    }
    if (rotate == 270) {
      return 90;
    }

    return 0;
  };

  return (
    <>
      {props.enable ? (
        <>
          <TooltipDimension
            isFlipped={props.insertDetail.isFlipped}
            styleProps={props.styleProps}
            coverRef={props.coverRef!}
            elementRef={separationLineRef}
            enableDimensionLine={false}
            enableDimensionBorder={true}
            enableStartEndPositions={true}
          >
            <div
              data-orientation={getOrientation()}
              data-default-orientation={props.defaultOrientation}
              ref={separationLineRef}
              style={{
                ...props.styleProps,
                ...{
                  height: "100%",
                  width: "100%",
                  left: "0",
                },
              }}
            />
          </TooltipDimension>
        </>
      ) : (
        <div
          className="disabled-line"
          style={{ ...props.styleProps, ...{ backgroundColor: "transparent" } }}
        />
      )}
    </>
  );
};

export default GridLine;

import { Box, Button, Grid, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import DesignPreviewThumb from "../../DesignPreview/DesignPreviewThumb";
import SetQuantityAndPrice from "../Table/ProjectSummaryTable-SetQuantityAndPrice";
import { ReactSVG } from "react-svg";
import { EDesignStatus, EDesignStatusDescription } from "../../../../enum/EDesignStatus";
import Actions from "../Table/ProjectSummaryActions";
import { IProductDesignSummaryResponse } from "../../../../interfaces/Project/Response/ProductDesignSummaryResponse";
import { useTranslation } from "react-i18next";
import { IProductDesignSummaryDto } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import { Dispatch, SetStateAction } from "react";
import EditSvgIcon from "../../Icons/EditIcon.svg";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
interface ICardProps {
  projects: IProductDesignSummaryResponse[];
  productDesignSummary: IProductDesignSummaryDto[];
  setCoverCost: Dispatch<SetStateAction<number>>;
  refeshScreen: () => void;
}

export const Card = ({ projects, productDesignSummary, setCoverCost, refeshScreen }: ICardProps) => {
  const navigate: any = useNavigate();
  const { setUserDesignDataFromProductDesignResponse, thunkGetProductDesignDataByProjectId, thunkGetProductDesignSummaries } = useStoreActions((actions) => actions.designs);
  const theme = useTheme();

  const { productDesigns } = useStoreState((state) => state.designs.data);

  const { t } = useTranslation();

  const onDraft = (productDesignId: string) => {
    const productDesign = productDesigns.find((x) => x.id == productDesignId)!;
    setUserDesignDataFromProductDesignResponse(productDesign);
    thunkGetProductDesignDataByProjectId(productDesign.project.id!);
    thunkGetProductDesignSummaries({ projectId: productDesign.project.id! })
    navigate(productDesign.design === null ? "/start-configurator" : productDesign.pathname, {
      state: { selectedCoverNumber: 0, isDesignSelected: true },
    });
  };

  return (
    <>
      {projects.map((productDesign, index) => {
        const summary = productDesignSummary.find((_item) => {
          return _item.id === productDesign.id;
        });
        productDesign.imagePreviewUrl = "";
        return (
          <TableRow key={index}>
            <TableCell
              sx={{ width: 459 }}
              className="projectSummaryTable_cell"
            >
              <Box component="div" sx={{ display: "inline-flex" }}>
                <Box sx={{ paddingLeft: "22px !important", paddingRight: "32px !important" }}>
                  <DesignPreviewThumb
                    productDesignId={productDesign.id}
                    productDesign={productDesign}
                  />
                </Box>
                <Grid
                  container
                  className="projectSummaryTable_cell_container"
                >
                  <Grid
                    color="#000"
                    container
                    item
                    className="projectSummaryTable_cell_cover"
                  >
                    {productDesign.numberOfCovers === 1 ? t("design_summary_table.cover") : t("design_summary_table.cover_set")}
                  </Grid>
                  <Grid
                    container
                    item
                    className="projectSummaryTable_cell_designrange"
                  >
                    {t("Color")}: <label className="detail">{productDesign.design?.frameColor.name}</label>
                  </Grid>
                  <Grid
                    container
                    item
                    className="projectSummaryTable_cell_numbercovers"
                  >
                    {t("DesignSummary_NumberOfCovers")}{" "}
                    <label className="detail">{productDesign.insertDetails.length}</label>
                  </Grid>
                </Grid>
              </Box>
            </TableCell>
            <SetQuantityAndPrice
              id={productDesign.id}
              price={summary?.price ?? 0}
              quantity={productDesign.quantity}
              numberOfCovers={summary?.numberOfCovers ?? 0}
              designStatus={productDesign.designStatus}
              productDesign={productDesigns.find((pd) => pd.id == productDesign.id)!}
              setCoverCost={setCoverCost}

            />
            <TableCell
              width={122}
              className={`projectSummaryTable_cell${productDesign.designStatus == EDesignStatus.Draft ? "draft" : ""}`}
            >
              {(productDesign.designStatus == EDesignStatus.Draft && (
                <Button
                  onClick={() => onDraft(productDesign.id)}
                  sx={{ textTransform: "capitalize", fontSize: "14px", fontWeight: 400, lineHeight: "20px" }}
                  startIcon={
                    <ReactSVG
                      src={EditSvgIcon}
                      beforeInjection={(svg) => {
                        svg.setAttribute("style", `width: 16px; height: 16px; fill: ${theme.palette.primary.main}`);
                      }}
                    />
                  }
                >
                  {t(`${EDesignStatusDescription.get(productDesign.designStatus)}`)}
                </Button>
              )) || (
                  <Typography
                    sx={{
                      color: "#000000",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    {t(`${EDesignStatusDescription.get(productDesign.designStatus)}`)}
                  </Typography>
                )}
            </TableCell>
            <TableCell
              align="center"
              width={119}
              sx={{
                paddingleft: 22
              }}

            >
              <Box
                display={"block"}
                margin={"auto"}
              >
                <Actions
                  productDesignId={productDesign.id}
                  designStatus={productDesign.designStatus}
                  projectId={projects[index].project.id}
                  refeshScreen={refeshScreen}
                />
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

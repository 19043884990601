import { Button, ButtonProps, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../../App";
import { ABB, BJE } from "../../../../../constants/Theme";

interface IButtonProps extends ButtonProps {
  disabled: boolean;
  handleSelectProject: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const AbbNext: React.FC<IButtonProps> = ({ disabled, handleSelectProject }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 96, height: 40 }}
      disabled={disabled}
      onClick={handleSelectProject}
      variant="contained"
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
        {t("select_existing_project_dialog.button.next")}
      </Typography>
    </Button>
  );
};

const BjeNext: React.FC<IButtonProps> = ({ disabled, handleSelectProject }) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{ width: 193, height: 41 }}
      style={{
        textTransform: "unset",
      }}
      disabled={disabled}
      onClick={handleSelectProject}
      variant="contained"
    >
      {t("select_existing_project_dialog.button.next")}
    </Button>
  );
};

const NextButton: React.FC<IButtonProps> = ({ disabled, handleSelectProject }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbNext
          disabled={disabled}
          handleSelectProject={handleSelectProject}
        />
      )) ||
        (theme.name == BJE && (
          <BjeNext
            disabled={disabled}
            handleSelectProject={handleSelectProject}
          />
        ))}
    </>
  );
};

export default NextButton;
